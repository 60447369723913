import React, { useContext } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import ButtonWrapper from 'components/Button/ButtonWrapper';
import Button from 'components/Button';
import { AuthContext } from 'contexts/authContext';
import styled from 'styled-components/macro';
import { useRenewJWT } from 'services/Auth';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../../utils/auth';

const Message = styled.p`
  text-align: center;
`;

function SessionExpirationModal() {
  const { setSessionWillTerminate, warningModelOpen, setWarningModalOpen, clearIntervals } = useContext(AuthContext);
  const renewJWT = useRenewJWT();
  const navigate = useNavigate();

  const toggle = e => {
    e.preventDefault();
    setWarningModalOpen(!warningModelOpen);
    setSessionWillTerminate(true);
  };

  const renewJwtToken = async () => {
    clearIntervals();
    try {
      await renewJWT();
      setWarningModalOpen(false);
    } catch (err) {
      setWarningModalOpen(false);
      navigate('/logout');
    }
  };

  return (
    <Modal isOpen={isAuthenticated() && warningModelOpen} toggle={toggle} centered>
      <ModalBody>
        <Message>Your session is about to expire, would you like to extend?</Message>
        <ButtonWrapper>
          <Button kind='tertiary' onClick={toggle}>
            Cancel
          </Button>
          <Button kind='primary' onClick={renewJwtToken}>
            Yes
          </Button>
        </ButtonWrapper>
      </ModalBody>
    </Modal>
  );
}

export default SessionExpirationModal;
