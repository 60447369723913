import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import styled, { css } from 'styled-components/macro';
import { theme } from 'styled-tools';

const commonToolTipStyles = css`
  .tooltip-inner {
    text-align: left;
    background-color: ${theme('colors.white')};
    color: ${theme('colors.greenAqua')};
    border: 1px solid ${theme('colors.greenAqua')};
  }
`;

const StyledTooltip = styled(Tooltip)`
  ${commonToolTipStyles}
`;

export const StyledUncontrolledTooltip = styled(UncontrolledTooltip)`
  ${commonToolTipStyles}
`;

const TooltipIcon = styled(FontAwesomeIcon)`
  color: ${theme('colors.greenAqua')};
`;

const SmallTooltip = ({ id, text, placement }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <TooltipIcon id={id} icon={faQuestionCircle} />
      <StyledTooltip placement={placement} isOpen={tooltipOpen} target={id} toggle={toggle}>
        {text}
      </StyledTooltip>
    </>
  );
};

SmallTooltip.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
  ]),
};

export default SmallTooltip;
