import { gql } from '@apollo/client';

export const GET_MODULES = gql`
  query getModules(
    $page: Int!
    $market: ID
    $sortOrder: String
    $sortField: String
    $q: String
    $presenters: [ID]
    $categories: [ID]
    $privateContent: Boolean
    $isBadgeContent: Boolean
  ) {
    pagedModules(
      page: $page
      sortOrder: $sortOrder
      sortField: $sortField
      filter: {
        q: $q
        markets: $market
        presenters: $presenters
        categories: $categories
        privateContent: $privateContent
        isBadgeContent: $isBadgeContent
      }
    ) {
      modules {
        id
      }
    }
  }
`;

export const GET_PAGED_MODULES = gql`
  query getModules(
    $page: Int!
    $perPage: Int!
    $market: ID
    $sortOrder: String
    $sortField: String
    $q: String
    $presenters: [ID]
    $categories: [ID]
    $privateContent: Boolean
    $isBadgeContent: Boolean
  ) {
    pagedModules(
      page: $page
      perPage: $perPage
      sortOrder: $sortOrder
      sortField: $sortField
      filter: {
        q: $q
        markets: $market
        presenters: $presenters
        categories: $categories
        privateContent: $privateContent
        isBadgeContent: $isBadgeContent
      }
    ) {
      modulesCount
      modules {
        id
        title
        description
        publishingStatus
        catalogNumber
        markets
        accessLevel
        createdBy
        updatedBy
        categories
        learningCredits
        learningCreditsNote
        additionalCertificates
        isMasaContent
        hasIsbeCompletionCertificate
        videoThumbnailLink
        playingTimeInSeconds
        video {
          videoCaptionLink
        }
        presenters {
          id
          name
        }
        quiz {
          enabled
        }
      }
    }
  }
`;

export const GET_ALL_MARKETS = gql`
  query getAllMarkets {
    markets {
      id
      name
    }
  }
`;
