import React from 'react';
import styled from 'styled-components/macro';
import { up, down } from 'styled-breakpoints';
import { theme } from 'styled-tools';
import { P } from '../Typography';
import { Col, Row } from 'reactstrap';
import moment from 'moment';

const FooterWrapper = styled.div`
  background-color: ${theme('colors.footer.background')};
`;

const FooterContainer = styled(Row)`
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${theme('breakpoints.lgDesktop')};
  padding: 1rem 1.25rem;

  ${up('lgMobile')} {
    padding: 1rem 2.5rem;
  }

  ${up('smTablet')} {
    flex-direction: row;
  }
`;

const FooterCopy = styled(P)`
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0;
  text-align: center;

  ${up('smTablet')} {
    line-height: 0.75rem;
    text-align: right;
  }
`;

const FooterSection = styled(Col)`
  color: ${theme('colors.white')};

  &:first-child {
    ${FooterCopy} {
      text-align: left;
    }
  }

  &:last-child {
    margin-bottom: 2rem;

    ${up('lgTablet')} {
      margin-bottom: 0;
    }
  }

  p + p {
    margin-top: 0.5rem;

    ${up('lgMobile')} {
      margin-top: 0.375rem;
    }
  }
`;

const ContactLink = styled.a`
  color: ${theme('colors.white')};
  text-decoration: underline;

  &:hover {
    color: ${theme('colors.white')};
  }
`;

const ContactInfo = styled.span`
  ${down('mdMobile')} {
    display: block;
  }
`;

const Divider = styled.span`
  display: none;

  ${up('lgMobile')} {
    display: inline;
  }
`;

function Footer() {
  return (
    <FooterWrapper role='contentinfo'>
      <FooterContainer>
        <FooterSection>
          <FooterCopy>Copyright &copy; {moment().year()} Infinitec</FooterCopy>
          <FooterCopy>
            This site is designed to meet Section 508 accessibility standards through compliance with WCAG2.0 A and AA
            guidelines. If you encounter any accessibility issues using the site, please reach out to our help desk at{' '}
            <a href='mailto:infinitecwebsupport@ucpnet.org'>infinitecwebsupport@ucpnet.org.</a>
          </FooterCopy>
        </FooterSection>
        <FooterSection>
          <FooterCopy>
            <ContactInfo>Contact Us: 7550 W. 183rd St. Tinley Park, IL 60477</ContactInfo>
            <Divider> | </Divider>
            <ContactInfo>
              <ContactLink href='tel:708-444-8460'>708-444-8460</ContactLink>
            </ContactInfo>
          </FooterCopy>
          <FooterCopy>
            <ContactInfo>
              Technical Support: <ContactLink href='tel:1-800-361-0270'>1-800-361-0270</ContactLink>
            </ContactInfo>
            <Divider> | </Divider>
            <ContactInfo>
              <ContactLink href='mailto:infinitecwebsupport@ucpnet.org'>infinitecwebsupport@ucpnet.org</ContactLink>
            </ContactInfo>
          </FooterCopy>
        </FooterSection>
      </FooterContainer>
    </FooterWrapper>
  );
}

export default Footer;
