import { useQuery, gql } from '@apollo/client';
import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import styled from 'styled-components/macro';
import { down } from 'styled-breakpoints';
import { FieldLabel, ErrorMessage, TypeaheadInput } from 'components/Form';

const SelectWrapper = styled.div`
  min-height: 5rem;

  ${down('lgMobile')} {
    margin-bottom: 1rem;
  }
`;

const GET_AGENCIES_BY_STATE = gql`
  query getAgenciesByState($stateId: ID!) {
    agencies(filter: { state: $stateId }) {
      value: id
      label: name
    }
  }
`;

function SelectEmployer({ selectedState, memberAgency, initMemberAgency = null }) {
  const [agencies, setAgencies] = useState([]);

  useQuery(GET_AGENCIES_BY_STATE, {
    fetchPolicy: 'cache-first',
    skip: !selectedState,
    variables: {
      stateId: selectedState,
    },
    onCompleted: data => {
      setAgencies(data.agencies);
    },
  });

  const selectedAgency = useMemo(() => {
    return agencies && memberAgency && agencies.find(agency => agency.value === memberAgency);
  }, [agencies, memberAgency]);

  const initSelectedAgency = useMemo(() => {
    return agencies && initMemberAgency && agencies.find(agency => agency.value === initMemberAgency);
  }, [agencies, initMemberAgency]);

  const getSelected = useCallback(
    (pristine, formValue) => {
      if (!formValue) {
        return [];
      } else if (selectedAgency) {
        return [selectedAgency];
      } else if (initSelectedAgency && pristine) {
        return [initSelectedAgency];
      } else {
        return null;
      }
    },
    [selectedAgency, initSelectedAgency]
  );

  return (
    <Field name='memberAgency' type='select'>
      {({ input, meta }) => {
        return (
          <SelectWrapper>
            <FieldLabel>Employer</FieldLabel>
            <TypeaheadInput
              {...input}
              placeholder='Select an Employer'
              options={agencies}
              multiple={false}
              selected={getSelected(meta.pristine, input.value)}
              disabled={!agencies.length || selectedAgency}
              invalid={meta.error && meta.touched}
            />
            {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
          </SelectWrapper>
        );
      }}
    </Field>
  );
}

SelectEmployer.propTypes = {
  selectedState: PropTypes.string,
  memberAgency: PropTypes.string,
  initMemberAgency: PropTypes.string,
};

export default SelectEmployer;
