import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { theme, ifProp } from 'styled-tools';
import { Field } from 'react-final-form';
import FieldLabel from 'components/Form/FieldLabel';
import { ErrorMessage } from '../index';

const InputWrapper = styled.div`
  margin-bottom: 1rem;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 0.4rem 0.875rem;
  outline: none;
  border-radius: ${theme('borderRadius')};
  background-color: ${theme('colors.white')};
  border: 0.0625rem solid ${theme('colors.mediumGray')};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 1rem;

  ${ifProp(
    'invalid',
    css`
      border-color: ${theme('colors.red')};
    `
  )}

  ${ifProp(
    'disabled',
    css`
      background-color: ${theme('colors.lightGray')};
    `
  )}
`;

function TextareaInput({ name, label, placeholder, disabled = false }) {
  return (
    <Field name={name} type={'textarea'}>
      {({ input, meta }) => (
        <InputWrapper>
          {label && <FieldLabel>{label}</FieldLabel>}
          <StyledTextarea
            {...input}
            invalid={meta.error && meta.touched}
            placeholder={placeholder}
            disabled={disabled}
            aria-label={label || name}
          />
          {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
        </InputWrapper>
      )}
    </Field>
  );
}

TextareaInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TextareaInput;
