import * as Excel from 'exceljs';

/**
 * @param {Array} dataObject Array of JSON objects
 * @param {String} sheetName The filename and sheet name
 * @param {Array<string, Object>} cellStyles the Key is the cell (A1, B1...) and the value is an object of css rules
 * @param {Array<string, string|number>} rowHeights
 * @param {boolean} ignoreHeaders Determines whether Row 0 will have headers.  Headers would be generated via the key of a dataObjects.
 * @param {Array<string>} headers Array of header names in order to specify for spreadsheet instead of the generated ones
 */
export const processSpreadsheet = ({
  dataObject,
  sheetName,
  cellStyles = [],
  rowHeights = [],
  ignoreHeaders = false,
  headers,
}) => {
  const workBook = new Excel.Workbook();
  const currentSheet = workBook.addWorksheet(sheetName, { properties: { defaultColWidth: 20 } });

  const fileName = sheetName + '.xlsx';
  let sheetHeaders = headers ? headers : [];

  const headerRowStep = ignoreHeaders ? 0 : 1; // If headers, we need to skip the first (0) row.

  dataObject &&
    dataObject.forEach(function(aRow, rowNumber) {
      Object.keys(aRow).forEach((key, i) => {
        // currentCell would look like 'A1'
        const currentCell =
          headers && !ignoreHeaders
            ? `${numToAlpha(headers.indexOf(key)).toUpperCase()}${rowNumber + headerRowStep + 1}`
            : `${numToAlpha(i).toUpperCase()}${rowNumber + headerRowStep + 1}`;

        if (!ignoreHeaders && !headers) {
          if (sheetHeaders.indexOf(key) < 0) {
            sheetHeaders.push(key); // Keep track of keys if they are being outputted.
          }
        }

        if (rowHeights[rowNumber]) {
          currentSheet.getRow(rowNumber).height = rowHeights[rowNumber];

          // For MS Excel to show wrapped text properly, need this setting.
          // applying to row with more height for now as the only reason a row has
          // more height is due to needing wrapped text.
          currentSheet.getRow(rowNumber).alignment = { wrapText: true };
        }

        if (currentCell && aRow[key] !== '') {
          currentSheet.getCell(currentCell).value = aRow[key];

          if (cellStyles[currentCell]) {
            currentSheet.getCell(currentCell).font = cellStyles[currentCell];
          }
        }
      });
    });

  // Add headers to Row 0:
  if (!ignoreHeaders) {
    sheetHeaders.forEach((headerLabel, i) => {
      currentSheet.getCell(`${numToAlpha(i).toUpperCase()}1`).value = headerLabel;
    });
  }

  // Download the spreadsheet.
  downloadSpreadsheet(workBook, fileName);
};

/**
 * Converts a number to a character : A-Z, AA-ZZ, AAA-ZZZ
 */
export const numToAlpha = i => {
  return (i >= 26 ? numToAlpha(((i / 26) >> 0) - 1) : '') + 'abcdefghijklmnopqrstuvwxyz'[i % 26 >> 0];
};

export function downloadSpreadsheet(workBook, fileName) {
  workBook.xlsx.writeBuffer().then(function(data) {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = fileName;
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
}
