import React from 'react';
import { AllBadgesProvider } from './allBadges.context';
import AllBadgesTable from './components/AllBadgesTable';

const AllBadges = () => {
  return (
    <AllBadgesProvider>
      <AllBadgesTable />
    </AllBadgesProvider>
  );
};

export default AllBadges;
