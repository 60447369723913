import styled, { css } from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import { theme } from 'styled-tools';
import TextInput from './TextInput';
import TextareaInput from './TextareaInput';
import RichTextareaInput from './RichTextareaInput';
import CheckboxInput from './CheckboxInput';
import SelectInput from './SelectInput';
import MultiSelectInput from './MultiSelectInput';
import RecaptchaInput from './RecaptchaInput';
import FieldLabel from './FieldLabel';
import PasswordInput from './PasswordInput';
import MaskedField from './MaskedField';
import TypeaheadInput from './TypeaheadInput';
import DatePickerInput from './DatePickerInput';
import RadioButtonInput from './RadioButtonInput';
import FileInput from './FileInput';
import StaticSelectInput from './SelectInput/StaticSelect';
import IconInput from './IconInput';
import MatrixTable from './MatrixTable';
import NumberInput from './NumberInput';

export const StyledInput = styled.input`
  ${ifProp('$noFixedWidth', 'flex-grow: 1', `width: 100%`)};
  padding: 0.4rem 0.875rem;
  outline: none;
    
  ${ifProp(
    '$noLeftRadius',
    '',
    css`
      border-top-left-radius: ${theme('borderRadius')};
      border-bottom-left-radius: ${theme('borderRadius')};
    `
  )}
  ${ifProp(
    '$noRightRadius',
    '',
    css`
      border-top-right-radius: ${theme('borderRadius')};
      border-bottom-right-radius: ${theme('borderRadius')};
    `
  )}

  background-color: ${theme('colors.white')};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 1rem;

  &:focus {
    border: 0.0625rem solid ${theme('colors.greenAqua')};
  }

  ${ifProp(
    'disabled',
    css`
      background-color: ${theme('colors.disabledGray')};
    `
  )}

  ${ifProp(
    'invalid',
    css`
      border: 0.0625rem solid ${theme('colors.red')};
    `,
    css`
      border: 0.0625rem solid ${theme('colors.mediumGray')};
    `
  )}
`;

export const InputWrapper = styled.div`
  height: ${props => (props.heightAuto ? 'auto' : '3rem')};
  margin-bottom: 1rem;

  &.email-input {
    input {
      border: 1px solid ${theme('colors.red')};
    }
  }

  &.full-width {
    width: 100%;
  }

  &.less-bottom-margin {
    margin-bottom: 0.3rem;
  }
`;

export const ErrorMessage = styled.div`
  color: ${theme('colors.darkRed')};
  font-size: 0.85rem;
  font-weight: 500;
  line-height: 0.85rem;
  margin-top: 0.125rem;
`;

export {
  TextInput,
  TextareaInput,
  PasswordInput,
  FieldLabel,
  CheckboxInput,
  SelectInput,
  StaticSelectInput,
  MultiSelectInput,
  RecaptchaInput,
  MaskedField,
  TypeaheadInput,
  RadioButtonInput,
  FileInput,
  DatePickerInput,
  IconInput,
  MatrixTable,
  NumberInput,
  RichTextareaInput,
};
