import React from 'react';
import { StaticSelectInput } from 'components/Form';

const sortTypes = [
  {
    id: 'most-recent-first',
    name: 'Most Recent First',
    default: true,
    sorting: {
      field: 'startDate',
      order: 'desc',
    },
  },
  {
    id: 'oldest-first',
    name: 'Oldest First',
    sorting: {
      field: 'startDate',
      order: 'asc',
    },
  },
  {
    id: 'alphabetical',
    name: 'Alphabetical',
    sorting: {
      field: 'name',
      order: 'asc',
    },
  },
];

function CampaignSortFilter({ disabled, setSorting }) {
  const handleSetSorting = sortingValue => {
    if (!sortingValue) return;

    const { sorting } = sortTypes.find(st => st.id === sortingValue);
    setSorting(sorting);
  };

  return (
    <>
      <label htmlFor='sort-filter'>Sort By</label>
      <StaticSelectInput
        name='sort-filter'
        onChange={e => handleSetSorting(e.target.value)}
        defaultValue={sortTypes.find(st => st.default).id}
        disabled={disabled}
      >
        {sortTypes.map(m => (
          <option key={m.id} value={m.id}>
            {m.name}
          </option>
        ))}
      </StaticSelectInput>
    </>
  );
}

CampaignSortFilter.propTypes = {};

export default CampaignSortFilter;
