import React from 'react';
import useGoogleAnalyticsListener from '../../hooks/useGoogleAnalyticsListener';
import useLogoutReferrer from '../PrivateRoute/useLogoutReferrer';
import Nav from '../Nav';
import Loader from '../Loader';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../Footer';
import SessionExpirationModal from '../SessionExpirationModal';
import styled from 'styled-components/macro';

const SiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
`;

const SiteContent = styled.div`
  flex: 1 0 auto;
`;

function Layout({ loading, rootCustomPages }) {
  const location = useLocation();

  useGoogleAnalyticsListener();
  useLogoutReferrer();

  return (
    <SiteWrapper>
      <SiteContent>
        {loading ? (
          <Loader />
        ) : (
          <>
            {location.pathname !== '/login' && <Nav rootCustomPages={rootCustomPages} />}
            <Outlet />
          </>
        )}
      </SiteContent>
      <Footer />
      <SessionExpirationModal />
    </SiteWrapper>
  );
}

export default Layout;
