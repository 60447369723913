import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dompurify from 'dompurify';
import { Button } from 'routes/EngageAdmin/Dashboard/commonStyledElements';
import styled, { css } from 'styled-components/macro';
import { up } from 'styled-breakpoints';
import { ifProp } from 'styled-tools';
import { theme } from 'styled-tools';
import { H4, P } from 'components/Typography';
import { formatModuleRunningTime } from 'utils/functions';
import BadgeModal from './BadgeModal';
import { badgeShape } from 'config/shapes';

const BadgeListItemWrapper = styled.div`
  border-top: 1px solid ${theme('colors.lightGray')};
  padding: 1.25rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 100%;

  > * + * {
    margin-top: 1rem;
  }

  ${up('lgTablet')} {
    flex-direction: row;

    > * + * {
      margin-left: 1.5rem;
      margin-top: 0;
    }
  }
`;

const BadgeImageWrapper = styled.div`
  min-width: 6rem;

  img {
    height: 8rem;
    max-height: 100%;
    width: auto;
  }
`;

const BadgeLevel = styled.div`
  font-size: 0.75rem;

  > label {
    color: ${theme('colors.darkerGray')};
  }
`;

const BadgeTitle = styled(H4)`
  font-size: 0.875rem;
  cursor: pointer;
`;

const BadgeDescription = styled(P)`
  font-size: 0.75rem;
`;

const RightColumn = styled.div`
  width: 100%;
`;

const BadgeDetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;

  ${up('lgTablet')} {
    flex-direction: row;
  }
`;

const BadgeDetails = styled.div`
  flex: 1;

  ${up('lgTablet')} {
    min-width: 18rem;
  }
`;

const BadgeStatWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${up('lgTablet')} {
    justify-content: center;
    & + & {
      margin-top: 1.5rem;
    }

    ${ifProp(
      'outer',
      css`
        flex-direction: row;
      `,
      css`
        flex-direction: column;
      `
    )}
  }
`;

const BadgeStatWrapperInner = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  & a {
    text-decoration: none;
  }

  ${props =>
    props.$button &&
    `
    margin-left: 1rem;
  `}
`;

const BadgeStat = styled.div`
  > div {
    min-width: 6.25rem;
    font-size: 0.75rem;
    color: ${theme('colors.darkerGray')};

    ${up('lgTablet')} {
      min-width: initial;
    }
  }

  ${up('lgTablet')} {
    & + & {
      margin-top: 1rem;
    }
  }
`;

function BadgeGridItem({ template }) {
  const [modalOpen, setModalOpen] = useState(false);
  const { badge, markets } = template;

  return (
    <BadgeListItemWrapper>
      <BadgeImageWrapper>{badge.image && <img src={badge.image.signedUrl} alt={badge.name} />}</BadgeImageWrapper>
      <RightColumn>
        <BadgeDetailsWrapper>
          <BadgeDetails>
            <BadgeTitle
              onClick={() => {
                window.open(`/engage-admin/learning-campaign/create/${template.id}`);
              }}
            >
              {badge.name}
            </BadgeTitle>
            <BadgeDescription>
              <span
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(badge.description),
                }}
              />
            </BadgeDescription>
            {badge.level && (
              <BadgeLevel>
                <label>Badge level:</label> {badge.level}
              </BadgeLevel>
            )}
            <BadgeLevel>
              <label>Est. Time To Complete:</label>{' '}
              {template.totalRunTime ? formatModuleRunningTime(template.totalRunTime) : 'n/a'}
            </BadgeLevel>
          </BadgeDetails>
          <BadgeStatWrapper outer>
            <BadgeStatWrapper>
              <BadgeStat>
                {badge.estimatedTimeToComplete && (
                  <>
                    {badge.estimatedTimeToComplete}
                    <div>Estimated Time To Complete</div>
                  </>
                )}
              </BadgeStat>
            </BadgeStatWrapper>
            <BadgeStatWrapperInner $button>
              <Button onClick={() => setModalOpen(true)}>View</Button>
            </BadgeStatWrapperInner>
          </BadgeStatWrapper>
        </BadgeDetailsWrapper>
      </RightColumn>
      <BadgeModal
        isOpen={modalOpen}
        badge={badge}
        templateId={template.id}
        template={template}
        setIsOpen={setModalOpen}
        markets={markets}
        toggle={() => setModalOpen(!modalOpen)}
      />
    </BadgeListItemWrapper>
  );
}

BadgeGridItem.propTypes = {
  template: PropTypes.shape({
    badge: PropTypes.shape(badgeShape),
    markets: PropTypes.array,
  }),
};

export default BadgeGridItem;
