import React, { useState } from 'react';

export const buildFilterObj = ({ currentMarket, categoryFilters }) => {
  let filters = {};

  filters.market = currentMarket;

  if (categoryFilters && categoryFilters.length > 0) {
    filters.templateCategories = categoryFilters.map(category => category.name);
  }
  return filters;
};

export const AllBadgesContext = React.createContext({});

export function AllBadgesProvider({ children }) {
  // Filters
  const [userFilter, setUserFilter] = useState('');
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [learningCampaignFilter, setLearningCampaignFilter] = useState([]);
  const [badgeNameFilter, setBadgeNameFilter] = useState([]);

  return (
    <AllBadgesContext.Provider
      value={{
        userFilter,
        startDateFilter,
        endDateFilter,
        learningCampaignFilter,
        badgeNameFilter,
        setters: {
          setUserFilter,
          setStartDateFilter,
          setEndDateFilter,
          setLearningCampaignFilter,
          setBadgeNameFilter,
        },
      }}
    >
      {children}
    </AllBadgesContext.Provider>
  );
}
export const AllBadgesConsumer = AllBadgesContext.Consumer;
