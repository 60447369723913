import React from 'react';
import { CustomInput, Modal, ModalBody } from 'reactstrap';
import { theme } from 'styled-tools';
import { Button } from 'routes/EngageAdmin/Dashboard/commonStyledElements';
import { Link } from 'react-router-dom';
import { SectionLabel } from 'routes/Dashboard/components/styled';
import dompurify from 'dompurify';
import styled from 'styled-components/macro';

const BadgeOverview = styled.div`
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

const BadgeTileRow = styled.div`
  display: flex;
  margin: 1rem 0;
`;

const BadgeTileDetails = styled.div`
  & p {
    margin-bottom: 0.25rem;
  }
  & label {
    display: inline;
  }
`;

const BadgeImageWrapper = styled.img`
  margin-right: 1.25rem;
  object-fit: scale-down; /*prevents the image from being stretched*/
  align-self: flex-start;
  max-height: 12rem;
`;

const ButtonSection = styled.div`
  display: flex;

  & button {
    width: 50%;
  }

  & a {
    width: 50%;
  }
`;

const ModuleContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.75rem;
  border: 1px solid ${theme('colors.mediumGray')};
  border-radius: ${theme('borderRadius')};

  & div {
    margin-top: 0.5rem;
  }
`;

const QuizIndicator = styled(CustomInput)`
  vertical-align: middle;

  label {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const Module = ({ title, includeQuiz, id }) => {
  return (
    <ModuleContainer>
      {title}
      <QuizIndicator
        type='switch'
        id={`includeQuiz-${id}`}
        name='includeQuiz'
        label='Include Quiz'
        defaultChecked={includeQuiz}
        disabled
      />
    </ModuleContainer>
  );
};

const BadgeModal = ({ markets, isOpen, templateId, badge, template, setIsOpen, toggle }) => {
  return (
    <Modal toggle={toggle} isOpen={isOpen} size='lg' centered scrollable>
      <ModalBody>
        <BadgeTileRow>
          <BadgeImageWrapper src={badge.image.signedUrl} alt={badge.name} />

          <BadgeTileDetails>
            <SectionLabel>Badge Name:</SectionLabel>
            <h3>{badge.name}</h3>

            <SectionLabel>Badge Description:</SectionLabel>
            <p dangerouslySetInnerHTML={{ __html: dompurify.sanitize(badge.description) }} />
          </BadgeTileDetails>
        </BadgeTileRow>

        <SectionLabel>Badge Learning Outcome:</SectionLabel>
        <p dangerouslySetInnerHTML={{ __html: dompurify.sanitize(badge.learningOutcome) }} />

        <BadgeOverview>
          Deploying this badge will create a Learning Campaign containing the necessary badge setup. Below is the
          campaign data that will be used for that Learning Campaign.
        </BadgeOverview>

        <SectionLabel>Modules</SectionLabel>
        <QuizIndicator
          type='checkbox'
          name='Activity Sequence Enforced'
          label='Activity Sequence Enforced'
          disabled
          defaultChecked={template.enforceActivitySequence}
        />

        <Module title={badge.introModule.title} id={badge.introModule.id} includeQuiz={false} />

        {template.modules.map((module, idx) => (
          <Module
            title={module.module.title}
            id={module.module.id}
            key={`module-${module.module.id}`}
            includeQuiz={module.includeQuiz}
          />
        ))}

        <Module title={badge.outroModule.title} id={badge.outroModule.id} includeQuiz={true} />

        <ButtonSection>
          <Button kind='tertiary' onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button
            as={Link}
            to={`/engage-admin/learning-campaign/create/${templateId}`}
            aria-label={`Edit ${badge.name}`}
          >
            Create Campaign
          </Button>
        </ButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default BadgeModal;
