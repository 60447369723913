import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Collapse as BaseCollapse, Nav as BaseNav, Navbar as BaseNavBar, NavbarToggler } from 'reactstrap';
import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import { down, up } from 'styled-breakpoints';
import tabs from './tabs';
import NavLogo from './components/NavLogo';
import TabsContainer from './components/TabsContainer';

const Navbar = styled(BaseNavBar)`
  background: ${theme('colors.white')};
  box-shadow: ${theme('boxShadow')};
  height: 5rem;
  padding: 0;
`;

const NavbarContainer = styled.div`
  display: flex;
  height: inherit;
  margin: 0 auto;
  max-width: ${theme('breakpoints.lgDesktop')};
  padding: 0 2.5rem;
  width: 100%;

  ${down('smTablet')} {
    justify-content: space-between;
  }

  .navbar-toggler {
    border: none;
  }
`;

const Nav = styled(BaseNav)`
  align-items: center;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  display: none;

  ${up('lgTablet')} {
    display: flex;
  }
`;

const MobileNav = styled(BaseNav)`
  background: white;
  width: 100%;
  box-shadow: ${theme('boxShadow')};
`;

const Collapse = styled(BaseCollapse)`
  position: fixed;
  z-index: 100;
  width: 100%;
  top: 5rem;
`;

const useNavControls = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  return {
    isNavOpen,
    setIsNavOpen,
    toggleNav: () => setIsNavOpen(!isNavOpen),
  };
};

function Navigation({ rootCustomPages = [] }) {
  const { isNavOpen, toggleNav } = useNavControls(false);

  const navTabs = useMemo(() => {
    return [...tabs, ...rootCustomPages].sort((a, b) => a.order - b.order);
  }, [rootCustomPages]);

  return (
    <>
      <Navbar light fixed={'top'} expand='lg' aria-label='Desktop Navigation'>
        <NavbarContainer>
          <NavLogo />
          <Nav navbar>
            <TabsContainer navTabs={navTabs} />
          </Nav>
          <NavbarToggler onClick={toggleNav} />
        </NavbarContainer>
      </Navbar>
      <Collapse isOpen={isNavOpen} navbar role='navigation' aria-label='Mobile Navigation'>
        <MobileNav navbar>
          <TabsContainer navTabs={navTabs} isMobile={true} />
        </MobileNav>
      </Collapse>
    </>
  );
}

Navigation.propTypes = {
  rootCustomPages: PropTypes.array,
};

export default Navigation;
