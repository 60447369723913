/** global: localStorage */
const ENGAGE_TAB_TOKEN = 'c@xy-infinitec-selcted-engage-tab';
const WORK_SECTOR_TOKEN = 'c@xy-infinitec-selcted-work-sector';

export function setLocalStorageData(name, value, json = false) {
  if (typeof value === 'undefined') {
    value = null;
  }

  if (json) {
    value = JSON.stringify(value);
  }
  localStorage.setItem(name, value);
  window.dispatchEvent(new Event('storage'));
}

export function getLocalStorageData(name, json = false) {
  const data = localStorage.getItem(name);
  return data ? (json ? JSON.parse(data) : data) : null;
}

export const getEngageTab = () => localStorage.getItem(ENGAGE_TAB_TOKEN);
export const setEngageTab = engageTab => localStorage.setItem(ENGAGE_TAB_TOKEN, engageTab);

export const getWorkSector = () => localStorage.getItem(WORK_SECTOR_TOKEN);
export const setWorkSector = sectorId => localStorage.setItem(WORK_SECTOR_TOKEN, sectorId);
