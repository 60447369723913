import React, { useContext, useEffect, useState } from 'react';
import { NoResultsFound } from '../../commonStyledElements';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'components/Loader';
import ResultItem from './components/ResultItem';
import { TrainingAdminContext } from '../../context';
import FilterSection from './components/FilterSection';
import { ResultsListHeader, ResultsTab } from '../../commonStyledElements';
import useGetInfLearningTemplates from 'hooks/queries/useGetInfLearningTemplates/useGetInfLearningTemplates';

function InfLearningTemplates() {
  const [page, setPage] = useState(0);
  const {
    markets,
    categoryFilters,
    currentMarket,
    setters: { setCurrentMarket },
  } = useContext(TrainingAdminContext);

  useEffect(() => {
    setPage(0);
  }, [categoryFilters, currentMarket]);

  const { data, loading, fetchMore } = useGetInfLearningTemplates();

  const infLearningTemplates = data?.pagedInfLearningTemplates?.infLearningTemplates || [];
  const hasMore = infLearningTemplates.length < data?.pagedInfLearningTemplates?.infLearningTemplatesCount;

  const loadMore = () => {
    fetchMore({
      variables: { page: page + 1 },
    }).then(() => {
      setPage(page + 1);
    });
  };

  return (
    <InfiniteScroll
      loadMore={() => !loading && loadMore()}
      hasMore={hasMore && !loading}
      loader={
        <div key={'loader'}>
          <Loader />
        </div>
      }
    >
      {infLearningTemplates && (
        <>
          <ResultsListHeader>
            {markets?.map(market => {
              return (
                <ResultsTab
                  key={market.id}
                  active={currentMarket === market.id}
                  onClick={() => setCurrentMarket(market.id)}
                  tabIndex='0'
                >
                  {market.name}
                </ResultsTab>
              );
            })}
          </ResultsListHeader>
          <FilterSection />
          {infLearningTemplates.map(infLearningTemplate => (
            <ResultItem key={infLearningTemplate.id} infLearningTemplate={infLearningTemplate} />
          ))}
        </>
      )}
      {!loading && infLearningTemplates.length === 0 && <NoResultsFound>No INF Learning Path found</NoResultsFound>}
    </InfiniteScroll>
  );
}

InfLearningTemplates.propTypes = {};

export default InfLearningTemplates;
