import styled, { css } from 'styled-components/macro';
import { ifProp, switchProp } from 'styled-tools';
import Button from './StyledButton';

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 2.5rem;

  ${ifProp(
    'flush',
    css`
      margin-top: 0;
    `
  )}

  ${switchProp('align', {
    center: css`
      text-align: center;
    `,
    left: css`
      text-align: left;
    `,
  })}

  ${Button} {
    &:last-of-type {
      margin-left: auto;
    }
  }
  
  ${ifProp(
    'flexWrap',
    css`
      flex-wrap: wrap;
    `
  )}
`;

export default ButtonWrapper;
