import React from 'react';

export const ErrorContext = React.createContext();

export function ErrorProvider({ children }) {
  const [error, setError] = React.useState();
  const [alert, setAlert] = React.useState();

  return <ErrorContext.Provider value={{ error, setError, alert, setAlert }}>{children}</ErrorContext.Provider>;
}

export const ErrorConsumer = ErrorContext.Consumer;
