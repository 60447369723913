import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { StyledInput, ErrorMessage, InputWrapper } from '../index';
import FieldLabel from 'components/Form/FieldLabel';

function NumberInput({ name, label, placeholder, autoComplete, step, min, max, heightAuto = false, disabled = false }) {
  return (
    <Field name={name} type='number' parse={value => parseFloat(value)}>
      {({ input, meta }) => (
        <InputWrapper heightAuto={heightAuto}>
          {label && <FieldLabel>{label}</FieldLabel>}
          <StyledInput
            invalid={meta.error && meta.touched}
            {...input}
            placeholder={placeholder}
            className={meta.error && meta.touched && 'input-invalid'}
            autoComplete={autoComplete}
            step={step}
            min={min}
            max={max}
            aria-label={label || name}
            disabled={disabled}
          />
          {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
        </InputWrapper>
      )}
    </Field>
  );
}

NumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  values: PropTypes.object,
  autoComplete: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  heightAuto: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default NumberInput;
