import React, { useContext, useState } from 'react';
import { Table } from 'reactstrap';
import styled from 'styled-components/macro';
import { formatDate } from 'utils';
import Paginator from 'components/Paginator';
import { P as BaseP } from 'components/Typography';
import { ResultsListItemWrapper } from 'routes/EngageAdmin/Dashboard/commonStyledElements';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import AllBadgesFilters from './AllBadgesFilters';
import { AllBadgesContext } from '../allBadges.context';
import UserBadgeImage from 'components/UserBadge/UserBadgeImage/UserBadgeImage';

const PER_PAGE = 15;

const GET_ALL_PAGED_USER_BADGES = gql`
  query getAllPagedUserBadges(
    $page: Int!
    $perPage: Int!
    $sortField: String
    $sortOrder: String
    $filters: AllPagedUserBadgesFilters
  ) {
    allPagedUserBadges(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filters: $filters
    ) {
      count
      userBadges {
        badgeId
        completedAt
        badgeMeta {
          name
          description
          level
          learningOutcome
          image {
            signedUrl
            type
          }
        }
        userMeta {
          firstName
          lastName
          email
          memberAgency {
            name
          }
        }
      }
    }
  }
`;

const PaginatorContainer = styled.div`
  padding: 0 1rem;
`;

const P = styled(BaseP)`
  padding: 1rem;
  text-align: center;
`;

function AllBadgesTable() {
  const [page, setPage] = useState(0);
  const [badges, setBadges] = useState([]);
  const [count, setCount] = useState();
  const { userFilter, startDateFilter, endDateFilter, learningCampaignFilter, badgeNameFilter } = useContext(
    AllBadgesContext
  );

  useQuery(GET_ALL_PAGED_USER_BADGES, {
    fetchPolicy: 'no-cache',
    variables: {
      page: page,
      perPage: PER_PAGE,
      sortField: 'createdAt',
      sortOrder: 'desc',
      filters: {
        badges: badgeNameFilter.map(b => b.name),
        learningCampaigns: learningCampaignFilter.map(e => e.name),
        user: userFilter,
        completedAt: {
          startDate: startDateFilter,
          endDate: endDateFilter,
        },
      },
    },
    onCompleted: data => {
      const { allPagedUserBadges } = data;
      setBadges(allPagedUserBadges.userBadges);
      setCount(allPagedUserBadges.count);
    },
  });

  return (
    <>
      <AllBadgesFilters />
      {badges && badges.length !== 0 ? (
        <ResultsListItemWrapper>
          <Table bordered responsive>
            <thead>
              <tr>
                <th>Badge</th>
                <th>Name</th>
                <th>Email</th>
                <th>Date Awarded</th>
              </tr>
            </thead>
            <tbody>
              {badges.map(item => (
                <tr key={`${item.badgeId}-${item.userMeta.email}`}>
                  <td>
                    <UserBadgeImage userBadge={item} />
                  </td>
                  <td>
                    {item.userMeta.firstName} {item.userMeta.lastName}
                  </td>
                  <td>{item.userMeta.email}</td>
                  <td>{formatDate(item.completedAt)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PaginatorContainer>
            <Paginator itemCount={count} page={page} perPage={PER_PAGE} setPage={setPage} />
          </PaginatorContainer>
        </ResultsListItemWrapper>
      ) : (
        <P>There are no certificates available to view at this time</P>
      )}
    </>
  );
}

export default AllBadgesTable;
