import { createFragmentRegistry } from '@apollo/client/cache';
import { gql } from '@apollo/client';

function getAppFragments() {
  return createFragmentRegistry(gql`
    fragment PresenterFragment on Presenter {
      id
      name
      title
    }
    fragment DashboardModuleFragment on Module {
      id
      description
      title
      playingTimeInSeconds
      quiz {
        enabled
      }
      presenters {
        ...PresenterFragment
      }
    }
    fragment QuizAttemptFragment on QuizAttempt {
      id
      status
      score
      completedAt
      certificateOfCompletion {
        id
      }
    }
    fragment QuizAttemptContentFragment on QuizAttempt {
      id
      status
      passingThresholdPercent
      module {
        id
        title
        presenters {
          id
          name
          title
        }
      }
      quizQuestions {
        id
        quizQuestionType
        questionText
        answerOptions {
          id
          answerText
        }
      }
    }
    fragment ModuleViewFragment on SystemEvent {
      id
      createdAt
    }
    fragment ModuleProgress on ModuleProgress {
      moduleId
      eventType
      module {
        id
      }
      moduleView {
        ...ModuleViewFragment
      }
      quizAttempt {
        ...QuizAttemptFragment
      }
      completedAt
    }
  `);
}

export default getAppFragments;
