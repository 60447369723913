import React from 'react';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components/macro';
import MatrixTableRow from './MatrixTableRow';

export const OptionHeaders = styled.label`
  font-size: 0.875rem;
  font-weight: bold;
`;

export const MatrixTableContainer = styled.div`
  margin-top: 1.25rem;
`;

export const OptionCol = styled(Col)`
  text-align: center;
`;

function MatrixTable({ label, options, children }) {
  return (
    <MatrixTableContainer>
      <label>{label}</label>
      <Row>
        <Col md={6} />
        {options.map(option => (
          <OptionCol key={option.value}>
            <OptionHeaders>{option.label}</OptionHeaders>
          </OptionCol>
        ))}
      </Row>
      {children.map(child => React.cloneElement(child, { options }))}
    </MatrixTableContainer>
  );
}

export { MatrixTableRow };

export default MatrixTable;
