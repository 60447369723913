import moment from 'moment';
import momentTimezone from 'moment-timezone';

export function formatDate(date, format = 'L') {
  return moment(date).format(format);
}

export const formatDateCentralTime = (date, format = 'L') => {
  return momentTimezone(date)
    .tz('America/Chicago')
    .format(format);
};
