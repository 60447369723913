import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'styled-tools';
import styled, { css } from 'styled-components/macro';
import { Arrow } from '../SelectInput';
import { Typeahead as BaseTypeahead } from 'react-bootstrap-typeahead';
import { ifProp } from 'styled-tools';

/***
 * Devs:
 *
 * 1) this component is not currently being exported for use for FinalFinal
 *
 * 2) The onChange function accepts an array of the select values, not an event object
 */

const TypeaheadWrapper = styled.div`
  position: relative;

  ${Arrow} {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
`;

const Typeahead = styled(BaseTypeahead)`
  .form-control {
    color: ${theme('colors.black')};
    border: 0.0625rem solid ${theme('colors.mediumGray')};
    border-radius: ${theme('borderRadius')};

    ${ifProp(
      'invalid',
      css`
        border: 0.0625rem solid ${theme('colors.red')};
      `
    )}
  }

  .rbt-menu {
    margin-bottom: 0.125rem;
    max-height: 10rem !important;
  }

  .rbt-menu > li a {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .rbt-menu > li a:focus {
    outline: none;
  }

  .rbt-menu-pagination-option {
    text-align: center;
  }

  .rbt .rbt-input-main::-ms-clear {
    display: none;
  }

  .rbt-input-multi {
    cursor: text;
    overflow: hidden;
    position: relative;
    height: auto;
  }

  .rbt-input-multi.focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0.5rem rgba(102, 175, 233, 0.6);
    border-color: ${theme('colors.inputColor')};
    outline: 0;
  }

  .rbt-input-multi.form-control[disabled] {
    background-color: #e9ecef;
    opacity: 1;
  }

  .rbt-input-multi input::-moz-placeholder {
    color: #999;
    opacity: 1;
  }

  .rbt-input-multi input:-ms-input-placeholder {
    color: #999;
  }

  .rbt-input-multi input::-webkit-input-placeholder {
    color: #999;
  }

  .rbt-input-multi .rbt-input-wrapper {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -0.25rem;
    margin-top: -0.0625rem;
    overflow: hidden;
  }

  .rbt-input-multi .rbt-input-main {
    height: 1.25rem;
    margin: 1px 0 0.25rem;
  }

  .rbt-input-multi.input-lg .rbt-input-main,
  .rbt-input-multi.form-control-lg .rbt-input-main {
    height: 1.5rem;
  }

  .rbt-input-multi.input-sm .rbt-input-main,
  .rbt-input-multi.form-control-sm .rbt-input-main {
    height: 1.125rem;
  }

  .rbt-close {
    z-index: 1;
  }

  .rbt-close-lg {
    font-size: 1.5rem;
  }

  .rbt-token {
    color: ${theme('colors.darkGray')};
    background: ${theme('colors.lightGray')};
    border: 0;
    border-radius: 0.125rem;
    display: inline-block;
    line-height: 1em;
    margin: 0 0.1875rem 0.1875rem 0;
    padding: 0.25rem 0.4375rem;
    position: relative;
    font-size: 0.6875rem;
  }

  .rbt-token-disabled {
    background-color: #ddd;
    color: #888;
    pointer-events: none;
  }

  .rbt-token-removeable {
    cursor: pointer;
    padding-right: 1.3125rem;
  }

  .rbt-token-active {
    background: ${theme('colors.lightGray')};
    color: ${theme('colors.darkGray')};
    outline: none;
    text-decoration: none;
  }

  .rbt-token .rbt-token-remove-button {
    bottom: 0;
    color: inherit;
    font-size: inherit;
    font-weight: normal;
    opacity: 1;
    outline: none;
    padding: 0.1875rem 0.4375rem;
    position: absolute;
    right: 0;
    text-shadow: none;
    top: -0.125rem;
  }

  .rbt-loader {
    -moz-animation: loader-animation 600ms infinite linear;
    -webkit-animation: loader-animation 600ms infinite linear;
    animation: loader-animation 600ms infinite linear;
    border: 1px solid ${theme('colors.mediumGray')};
    border-radius: 50%;
    border-top-color: ${theme('colors.mediumGray')};
    display: block;
    height: 1rem;
    width: 1rem;
  }

  .rbt-loader-lg {
    height: 1.25rem;
    width: 1.25rem;
  }

  .rbt-aux {
    align-items: center;
    display: flex;
    bottom: 0;
    justify-content: center;
    pointer-events: none;
    /* Don't block clicks on the input */
    position: absolute;
    right: 0;
    top: 0;
    width: 2.125rem;
  }

  .rbt-aux-lg {
    width: 2.875rem;
  }

  .rbt-aux .rbt-close {
    margin-top: -0.25rem;
    pointer-events: auto;
    /* Override pointer-events: none; above */
  }

  .has-aux .rbt-input {
    padding-right: 2.125rem;
  }

  .rbt-highlight-text {
    background-color: inherit;
    color: inherit;
    font-weight: bold;
    padding: 0;
  }

  /* Input Groups */
  .input-group > .rbt {
    flex: 1;
  }

  .input-group > .rbt .rbt-input-hint,
  .input-group > .rbt .rbt-aux {
    z-index: 5;
  }

  .input-group > .rbt:not(:first-child) .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group > .rbt:not(:last-child) .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  /* Validation States */
  .has-error .rbt-input-multi.focus {
    border-color: #843534;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  }

  .has-warning .rbt-input-multi.focus {
    border-color: #66512c;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  }

  .has-success .rbt-input-multi.focus {
    border-color: #2b542c;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  }

  @keyframes loader-animation {
    to {
      transform: rotate(1turn);
    }
  }
`;

const TypeaheadInput = ({ name, multiple, options, placeholder, selected, onChange, invalid, ...props }) => {
  return (
    <TypeaheadWrapper>
      <Typeahead
        id={`typeahead-${name}`}
        labelKey='label'
        name={name}
        multiple={multiple}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        selected={selected}
        invalid={invalid}
        {...props}
      />
      <Arrow />
    </TypeaheadWrapper>
  );
};

TypeaheadInput.propTypes = {
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  placeholder: PropTypes.string,
  selected: PropTypes.array,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
};

TypeaheadInput.defaultProps = {
  multiple: true,
  placeholder: '',
  selected: [],
  onChange: () => {},
  options: [],
};

export default TypeaheadInput;
