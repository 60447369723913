import PropTypes from 'prop-types';

export const customPageSidebarShape = PropTypes.shape({
  children: PropTypes.array,
  id: PropTypes.string.isRequired,
  menuRoot: PropTypes.string,
  parent: PropTypes.string,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

export const eventShape = {
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    title: PropTypes.string,
    type: PropTypes.string.isRequired,
    duration: PropTypes.string,
    allDayEvent: PropTypes.bool,
    description: PropTypes.string,
    location: PropTypes.string,
    address: PropTypes.string,
    registrationUrl: PropTypes.string,
    state: PropTypes.shape({
      name: PropTypes.string,
    }),
    region: PropTypes.shape({
      name: PropTypes.string,
    }),
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        linkText: PropTypes.string,
        document: PropTypes.shape({
          id: PropTypes.string,
          fileName: PropTypes.string,
        }),
      })
    ),
  }).isRequired,
};

export const badgeShape = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  number: PropTypes.string,
  level: PropTypes.string,
  description: PropTypes.string,
  learningOutcome: PropTypes.string,
  image: PropTypes.shape({
    id: PropTypes.string,
    signedUrl: PropTypes.string,
  }),
  introModule: PropTypes.object,
  outroModule: PropTypes.object,
};

export const userBadgeShape = {
  id: PropTypes.string,
  badgeMeta: PropTypes.shape({
    ...badgeShape,
    seatTime: PropTypes.number,
  }),
  completedAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

export const publicUserBadgeShape = {
  agency: PropTypes.string,
  completedAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  completedBy: PropTypes.string.isRequired,
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  image: PropTypes.string,
  learningOutcome: PropTypes.string,
  level: PropTypes.string,
  name: PropTypes.string.isRequired,
  seatTime: PropTypes.number,
};
