import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { theme, ifProp } from 'styled-tools';
import { Field } from 'react-final-form';
import DatePicker from 'reactstrap-date-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { ErrorMessage, FieldLabel } from '../index';

export const DateIcon = styled(FontAwesomeIcon).attrs({
  icon: faCalendarAlt,
})``;

export const StyledDatePicker = styled(DatePicker)`
  border: 0.0625rem solid ${theme('colors.mediumGray')};
  padding-left: 2.5rem;
  border-radius: ${theme('borderRadius')};

  &&& {
    border-top-right-radius: ${theme('borderRadius')};
    border-bottom-right-radius: ${theme('borderRadius')};
  }

  ${ifProp(
    'invalid',
    css`
      border-color: ${theme('colors.red')};
    `
  )}
`;

export const DatePickerWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
  /**
  * It wasn't possible to override these classes without !important.
   */
  .text-primary {
    color: ${theme('colors.primary')} !important;
  }

  .bg-primary {
    color: ${theme('colors.white')};
    background: ${theme('colors.primary')} !important;
  }

  ${DateIcon} {
    position: absolute;
    left: 1rem;
    top: 0.66rem;
    z-index: 999;
  }
`;

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  disabled: PropTypes.bool,
};

function DatePickerInput({ name, label, minDate, maxDate, disabled = false }) {
  return (
    <Field name={name} type='date'>
      {({ input, meta }) => {
        return (
          <div>
            {label && <FieldLabel htmlFor={`rdp-form-control-${name}`}>{label}</FieldLabel>}
            <DatePickerWrapper>
              <DateIcon title='calendar' />
              <StyledDatePicker
                invalid={meta.error && meta.touched}
                minDate={minDate}
                maxDate={maxDate}
                showClearButton={false}
                onChange={(value, formattedValue) => {
                  input.onChange({ target: { name, value } });
                }}
                disabled={disabled}
                {...input}
              />
              {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
            </DatePickerWrapper>
          </div>
        );
      }}
    </Field>
  );
}

DatePickerInput.propTypes = propTypes;

export default DatePickerInput;
