import { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { TrainingAdminContext, buildFilterObj } from 'routes/EngageAdmin/Dashboard/context';

export const GET_INF_BADGE_TEMPLATES = gql`
  query infBadgeTemplates {
    infBadgeTemplates {
      badge {
        id
        name
      }
    }
  }
`;

const useGetInfBadgeTemplates = options => {
  const { currentMarket } = useContext(TrainingAdminContext);

  return useQuery(GET_INF_BADGE_TEMPLATES, {
    fetchPolicy: 'no-cache',
    ...options,
    variables: {
      ...buildFilterObj({ currentMarket }),
    },
  });
};

export default useGetInfBadgeTemplates;
