export const VALIDATE_PASSWORD_ERROR_MESSAGE = 'Invalid password.';

export const VALIDATE_EMAIL_NOT_FOUND_MESSAGE = 'We can’t find your email or password. Please try again.';

export const VALIDATE_CURRENT_PASSWORD_ERROR_MESSAGE = 'Could not update password! Current password did not match.';

export const VALIDATE_LOGIN_GENERAL_ERROR = 'Sorry, something went wrong';

export const VALIDATE_EMAIL_NOT_VALID_MESSAGE = 'Please enter a valid email address.';

export const QUIZ_ATTEMPT_STATUSES = {
  PASS: 'Pass',
  FAIL: 'Fail',
  INCOMPLETE: 'Incomplete',
};

export const EVENT_TYPES = {
  webinar: 'Webinar',
  statewide: 'State Wide',
  regional: 'Regional',
};

export const CERTIFICATE_TYPES = {
  STANDARD: 'standard',
  BLENDED: 'blended',
  COMPLETION: 'completion',
  CAMPAIGN: 'campaign',
  ALL: 'all',
};

export const CAMPAIGN_TYPES = {
  ASSIGNMENT: 'assignment',
  RECOMMENDATION: 'recommendation',
};

export const CAMPAIGN_PROGRESS_STATUSES = {
  NOT_REGISTERED: 'not_registered',
  NOT_STARTED: 'not_started',
  IN_PROGRESS: 'in_progress',
  COMPLETE: 'complete',
};

export const CAMPAIGN_PROGRESS_STATUS_LABELS = {
  [CAMPAIGN_PROGRESS_STATUSES.NOT_REGISTERED]: 'Not Registered',
  [CAMPAIGN_PROGRESS_STATUSES.NOT_STARTED]: 'Not Started',
  [CAMPAIGN_PROGRESS_STATUSES.IN_PROGRESS]: 'In Progress',
  [CAMPAIGN_PROGRESS_STATUSES.COMPLETE]: 'Complete',
};

/**
 * Modules
 */
export const MODULE_PUBLISHING_STATUSES = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  ARCHIVED: 'archived',
};

// From the registration page, if any of these values are found in the url query
// string, they will be added back upon successful registration
export const URL_PARAMS_TO_PASS_ALONG = ['learningCampaign'];

export const STATE_ABBREVIATIONS = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

export const EVENT_TYPE_STATEWIDE = 'statewide';
export const EVENT_TYPE_REGIONAL = 'regional';
export const EVENT_TYPE_WEBINAR = 'webinar';

export const INF_LEARNING_TEMPLATE = 'inf-learning-template';
export const LEARNING_PATH = 'learning-path';

export const FORUM_NOTIFICATION_OPT_OUT = 'FORUM_NOTIFICATION_OPT_OUT';

export const ATTACHMENT_TYPE_MODULE = 'module';
export const ATTACHMENT_TYPE_EVENT = 'event';
export const ATTACHMENT_TYPE_FORUM = 'forum';
