import React from 'react';
import PropTypes from 'prop-types';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { isAuthenticated } from 'utils/auth';
import { USER_ROLE, userHasRole, userIsContracted } from 'utils/roles';

function PrivateRoute({ requiresContract = false, roles = Object.values(USER_ROLE) }) {
  const location = useLocation();
  const hasRoles = roles?.some(role => userHasRole(role));
  const meetsContractRequirements = (!requiresContract || userIsContracted());

  return isAuthenticated() && hasRoles && meetsContractRequirements
    ? <Outlet />
    : <Navigate
        to={isAuthenticated() ? `/403` : `/login`}
        search={location.search.replace(/[?&]referrer=[a-zA-Z]*/, '')}
        state={{ from: location }}
      />
}

PrivateRoute.propTypes = {
  requiresContract: PropTypes.bool,
  role: PropTypes.oneOf(Object.values(USER_ROLE)),
};

export default PrivateRoute;
