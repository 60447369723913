import { useQuery } from '@apollo/client';
import React, { useCallback, useContext, useState } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { debounce } from 'lodash';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { TrainingAdminContext } from 'routes/EngageAdmin/Dashboard/context';
import { GET_LEARNING_CAMPAIGNS } from 'routes/EngageAdmin/Dashboard/context/graphql';
import { MainSectionInner } from 'routes/EngageAdmin/LearningCampaign/components/common';
import { Button } from 'routes/EngageAdmin/Dashboard/commonStyledElements';
import { SearchIcon } from 'components/Icons';
import { FieldLabel, IconInput, InputWrapper, TypeaheadInput } from 'components/Form';
import { DateIcon, DatePickerWrapper, StyledDatePicker } from 'components/Form/DatePickerInput';
import usePagedQuery from '../../components/useUsers';
import { getToken, getUserData } from 'utils/auth';
import styled from 'styled-components/macro';

const ExportButton = styled(Button)`
  width: inherit;
  margin-top: 1.3rem;
`;

function AllCertificatesFilters() {
  const {
    learningCampaignFilter,
    startDateFilter,
    endDateFilter,
    moduleFilter,
    userFilter,
    setters: { setStartDateFilter, setEndDateFilter, setLearningCampaignFilter, setModuleFilter, setUserFilter },
  } = useContext(TrainingAdminContext);

  const [allLearningCampaigns, setAllLearningCampaigns] = useState([]);
  const { error, setError, alert, setAlert } = usePagedQuery();
  const [isDisabled, setIsDisabled] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const certificateUrl = `${process.env.REACT_APP_API_URL}/api/batch-certificate-download/?bearerToken=${getToken()}`;

  // Get all learning campaigns that are part of the current user's memberAgency
  useQuery(GET_LEARNING_CAMPAIGNS, {
    onCompleted: data => {
      const allLearningCampaigns = get(data, 'learningCampaigns', []);
      setAllLearningCampaigns(allLearningCampaigns);
    },
    onError: err => {
      setError('There was an error when loading Learning Campaigns. Please try again.');
    },
  });

  const debounceSetModuleFilter = useCallback(debounce(setModuleFilter, 500), []);
  const debounceSetUserFilter = useCallback(debounce(setUserFilter, 500), []);

  // Event handlers
  const handleLearningCampaignFilterChange = learningCampaignValue => {
    return setLearningCampaignFilter(learningCampaignValue);
  };
  const onModuleFilterChange = e => {
    return debounceSetModuleFilter(e.target && e.target.value);
  };
  const onUserFilterChange = e => {
    return debounceSetUserFilter(e.target && e.target.value);
  };
  const onStartDateFilterChange = e => {
    return setStartDateFilter(e);
  };
  const onEndDateFilterChange = e => {
    return setEndDateFilter(e);
  };

  const handleSendEmailClick = async e => {
    // Disable button once clicked
    setIsDisabled(true);
    e.preventDefault();

    // Display notification that email is sending
    try {
      await fetch(certificateUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getToken(),
        },
        body: JSON.stringify({
          user: getUserData(),
          startDateFilter: startDateFilter,
          endDateFilter: endDateFilter,
          learningCampaignFilter: learningCampaignFilter,
          moduleFilter: moduleFilter,
          userFilter: userFilter,
        }),
      }).then(() => {
        // Display notification
        setAlert('The email request is in process. This may take a few minutes.');
      });
    } catch (e) {
      // Display error message and re-enable button
      setError('Sorry, something went wrong when trying to send the email. Please try again.');
    } finally {
      // Re-enable button after request returns
      setShowAlert(true);
      setIsDisabled(false);
    }
  };

  return (
    <MainSectionInner>
      {error && (
        <Alert
          color='danger'
          toggle={() => {
            setShowAlert(false);
          }}
          isOpen={showAlert}
        >
          {error}
        </Alert>
      )}
      {alert && (
        <Alert
          toggle={() => {
            setShowAlert(false);
          }}
          isOpen={showAlert}
        >
          {alert}
        </Alert>
      )}
      <Row>
        <Col>
          <FieldLabel htmlFor='learning-campaign-filter'>Select a learning campaign</FieldLabel>
          <TypeaheadInput
            name='learningCampaigns'
            placeholder='Select a learning campaign'
            selected={learningCampaignFilter}
            options={
              allLearningCampaigns &&
              sortBy(
                allLearningCampaigns.map(category => ({ label: category.name, name: category.id })),
                ['label', 'name']
              )
            }
            onChange={selections => handleLearningCampaignFilterChange(selections)}
          />
        </Col>
        <Col>
          <InputWrapper heightAuto>
            <FieldLabel htmlFor='module-search'>Search modules</FieldLabel>
            <IconInput
              id='module-search'
              name='q'
              onChange={onModuleFilterChange}
              onKeyPress={onModuleFilterChange}
              icon={SearchIcon}
            />
          </InputWrapper>
        </Col>
      </Row>
      <Row>
        <Col sm={6} lg={6}>
          <FieldLabel htmlFor='start-date-search'>Start Date</FieldLabel>
          <DatePickerWrapper>
            <DateIcon title='calendar' />
            <StyledDatePicker showClearButton={false} onChange={onStartDateFilterChange} value={startDateFilter} />
          </DatePickerWrapper>
        </Col>
        <Col sm={6} lg={6}>
          <FieldLabel htmlFor='start-date-search'>End Date</FieldLabel>
          <DatePickerWrapper>
            <DateIcon title='calendar' />
            <StyledDatePicker showClearButton={false} onChange={onEndDateFilterChange} value={endDateFilter} />
          </DatePickerWrapper>
        </Col>
      </Row>
      <Row>
        <Col sm={6} lg={6}>
          <InputWrapper>
            <FieldLabel htmlFor='email-search'>Search staff members</FieldLabel>
            <IconInput
              id='user-search'
              name='q'
              type='email'
              onChange={onUserFilterChange}
              onKeyPress={onUserFilterChange}
              icon={SearchIcon}
            />
          </InputWrapper>
        </Col>
        <Col sm={6} lg={6}>
          <ExportButton disabled={isDisabled} onClick={handleSendEmailClick}>
            Email all zipped certificates
          </ExportButton>
        </Col>
      </Row>
    </MainSectionInner>
  );
}

export default AllCertificatesFilters;
