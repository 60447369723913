import React, { useContext, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { Row, Col } from 'reactstrap';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import styled from 'styled-components/macro';
import Alert from 'components/Alerts';
import { H3 } from 'components/Typography';
import CheckboxInput from 'components/Form/CheckboxInput';
import { ErrorMessage, IconInput } from 'components/Form';
import { SearchIcon } from 'components/Icons';
import DatePickerInput from 'components/Form/DatePickerInput';
import { ButtonWrapper } from 'components/Button';
import UserTable from './UserTable';
import usePagedQuery from './useUsers';
import { useGenerateQuizAttemptReport } from 'services/QuizAttemptService';
import { Button, NoResultsFound, ResultsListItemWrapper } from 'routes/EngageAdmin/Dashboard/commonStyledElements';
import { TrainingAdminContext } from 'routes/EngageAdmin/Dashboard/context';

const PER_PAGE = 15;

const UserReportWrapper = styled.div`
  padding: 1rem;
`;

const OptionsRow = styled(Row)`
  margin-bottom: 0.5rem;
`;

const initialValues = { users: [] };

const validate = ({ showDate, startDate, endDate }) => {
  const errors = {};

  // Check that selected date range is valid
  if (showDate && startDate && endDate && startDate > endDate) {
    errors.dates = 'Selected dates will result in empty report. Please select a start date prior to the end date.';
  }

  return errors;
};

function UsersResultItem() {
  const {
    page,
    users,
    totalUsers,
    userFilter,
    setters: { setUserFilter, setPage },
  } = useContext(TrainingAdminContext);
  const { error, setError } = usePagedQuery();
  const [searchFilter, setSearchFilter] = useState();
  const generateReport = useGenerateQuizAttemptReport(setError);

  useEffect(() => {
    setUserFilter(searchFilter);
  }, [searchFilter, setUserFilter]);

  const debounceSetSearchFilter = React.useCallback(debounce(setSearchFilter, 500), []);
  const onSearchChange = e => {
    const { value } = e.target;
    setUserFilter(value);
    debounceSetSearchFilter(value);
  };

  const onSubmit = async values => {
    const filter = {};
    if (values.singleSelect && values.users.length > 0) {
      filter.users = values.users;
    }

    if (values.showDate && values.startDate) {
      filter.startDate = values.startDate;
    }

    if (values.showDate && values.endDate) {
      filter.endDate = values.endDate;
    }

    generateReport(filter);
  };

  return (
    <>
      <ResultsListItemWrapper>
        <UserReportWrapper>
          {error && <Alert type='danger' alertText={error} />}
          <Form onSubmit={onSubmit} mutators={{ ...arrayMutators }} initialValues={initialValues} validate={validate}>
            {({ handleSubmit, values, errors, submitting }) => (
              <form onSubmit={handleSubmit}>
                <H3>Select Users</H3>
                <OptionsRow>
                  <Col sm={5} md={6}>
                    <CheckboxInput name='singleSelect'>Select one or more users</CheckboxInput>
                  </Col>
                  <Col sm={7} md={6}>
                    <IconInput
                      id='q'
                      name='q'
                      type='text'
                      onChange={onSearchChange}
                      icon={SearchIcon}
                      value={userFilter}
                    />
                  </Col>
                </OptionsRow>

                {users?.length ? (
                  <>
                    <FieldArray
                      name='users'
                      render={fieldArrayProps => (
                        <UserTable
                          users={users}
                          totalUsers={totalUsers}
                          currentPage={page}
                          setPage={setPage}
                          fieldArrayProps={fieldArrayProps}
                          itemsPerPage={PER_PAGE}
                          displaySelect={values.singleSelect}
                        />
                      )}
                    />
                    <H3>Date Selection</H3>
                    <CheckboxInput name='showDate'>Filter results by date</CheckboxInput>
                    {values.showDate && (
                      <Row>
                        <Col md={6}>
                          <DatePickerInput name='startDate' label='Start Date' maxDate={values.endDate} />
                        </Col>
                        <Col md={6}>
                          <DatePickerInput name='endDate' label='End Date' minDate={values.startDate} />
                        </Col>
                        <Col md={12}>{errors.dates && <ErrorMessage>{errors.dates}</ErrorMessage>}</Col>
                      </Row>
                    )}

                    <ButtonWrapper>
                      <Button type='submit' disabled={submitting}>
                        Download Report
                      </Button>
                    </ButtonWrapper>
                  </>
                ) : (
                  <NoResultsFound>No Users found</NoResultsFound>
                )}
              </form>
            )}
          </Form>
        </UserReportWrapper>
      </ResultsListItemWrapper>
    </>
  );
}

export default UsersResultItem;
