import { useLazyQuery, gql } from '@apollo/client';
import { numToAlpha, downloadSpreadsheet } from 'utils/reporting';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import * as Excel from 'exceljs';
import { formatDate } from 'utils';
import { QUIZ_ATTEMPT_STATUSES } from 'config/consts';

const GET_QUIZ_ATTEMPTS = gql`
  query getQuizAttempts($users: [ID], $createdAt: DateRangeFilter) {
    quizAttempts(filter: { user: $users, createdAt: $createdAt }) {
      id
      score
      status
      passingThresholdPercent
      createdAt
      user {
        id
        firstName
        lastName
        email
      }
      module {
        title
        learningCredits
      }
    }
  }
`;

const SPREADSHEET_HEADERS = [
  { label: 'First Name', key: 'user.firstName' },
  { label: 'Last Name', key: 'user.lastName' },
  { label: 'Email', key: 'user.email' },
  { label: 'Training Title', key: 'module.title' },
  { label: 'Completion Date', key: 'createdAt', format: val => formatDate(val) },
  { label: 'Quiz Status', key: 'status' },
  { label: 'Score', key: 'score', format: val => (val ? `${val}%` : 'n/a') },
  { label: 'Passing Threshold Score', key: 'passingThresholdPercent', format: val => `${parseFloat(val) * 100}%` },
  {
    label: 'Learning Credits',
    key: 'module.learningCredits',
    format: (val, row) => (row.status === QUIZ_ATTEMPT_STATUSES.PASS && val ? `${parseFloat(val)}` : ''),
  },
];

const processSpreadsheet = ({ dataObject, ignoreHeaders = false, headers = [] }) => {
  const workBook = new Excel.Workbook();
  const fileName = 'userReport.xlsx';
  const columns = headers;

  map(dataObject, quizAttempts => {
    const user = get(quizAttempts, '[0].user');

    let currentSheet;
    let sheetName = `${user.lastName}, ${user.firstName}; ${user.email}`;
    // logic is needed do to worksheet name length being limited to 31 characters
    let sheetNameIsUnique = false;
    let dupeCount = 0;
    do {
      try {
        currentSheet = workBook.addWorksheet(sheetName, { properties: { defaultColWidth: 20 } });
        sheetNameIsUnique = true;
      } catch (e) {
        sheetName = `${user.lastName}, ${user.firstName}(${++dupeCount}); ${user.email}`;
      }
    } while (!sheetNameIsUnique);

    quizAttempts.forEach(function(aRow, rowNumber) {
      columns.forEach(({ key, format }, i) => {
        // currentCell would look like 'A1';
        const currentCell = `${numToAlpha(i).toUpperCase()}${rowNumber + 2}`;

        // set value to cell
        const val = get(aRow, key, '');
        currentSheet.getCell(currentCell).value = format ? format(val, aRow) : val;
      });
    });

    // Add headers to Row 0:
    headers.forEach(({ label }, i) => {
      currentSheet.getCell(`${numToAlpha(i).toUpperCase()}1`).value = label;
    });
  });

  // Download the spreadsheet.
  downloadSpreadsheet(workBook, fileName);
};

function useGenerateQuizAttemptReport(setError) {
  const [fetchQuizAttempts] = useLazyQuery(GET_QUIZ_ATTEMPTS, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ quizAttempts }) => {
      setError && setError('');
      if (quizAttempts.length < 1) {
        setError && setError('The user(s) selected do not have any quiz history data.');
      } else {
        processSpreadsheet({
          headers: SPREADSHEET_HEADERS,
          dataObject: groupBy(quizAttempts, 'user.id'),
        });
      }
    },
    onError: error => {
      console.error(error);
    },
  });

  // accepts filter object
  const generateReport = ({ users, startDate, endDate }) => {
    const createdAt = startDate || endDate ? {} : undefined;
    if (startDate) {
      createdAt.startDate = startDate;
    }
    if (endDate) {
      createdAt.endDate = endDate;
    }

    fetchQuizAttempts({
      variables: {
        users: users,
        createdAt,
      },
    });
  };

  return generateReport;
}

export default useGenerateQuizAttemptReport;
