import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { StyledInput, ErrorMessage, InputWrapper } from '../index';
import FieldLabel from 'components/Form/FieldLabel';

const PasswordInput = ({ name, label, values, placeholder, autoComplete }) => {
  return (
    <Field name={name} type='password'>
      {({ input, meta }) => (
        <InputWrapper>
          {label && <FieldLabel>{label}</FieldLabel>}
          <StyledInput
            invalid={meta.error && meta.touched}
            {...input}
            placeholder={placeholder}
            className={meta.error && meta.touched && 'input-invalid'}
            autoComplete={autoComplete}
            aria-label={label || name}
          />
          {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
        </InputWrapper>
      )}
    </Field>
  );
};

PasswordInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  values: PropTypes.object,
  autoComplete: PropTypes.string,
};

export default PasswordInput;
