import styled, { css } from 'styled-components/macro';
import { NavLink } from 'reactstrap';
import { NavLink as RouteNavLink } from 'react-router-dom';
import { ifProp, theme } from 'styled-tools';

const StyledLink = styled(NavLink).attrs(props => ({
  as: props.as ? props.as : (props.to && RouteNavLink) || NavLink,
}))`
  display: block;
  padding: 0.5rem 1rem;

  &&& {
    color: ${theme('colors.black')};
    border-bottom: 0.125rem solid transparent;
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0;

    ${ifProp(
      { height: 'full' },
      css`
        align-items: center;
        display: flex;
        height: 100%;
      `
    )}

    &:hover {
      cursor: pointer;
    }

    &:hover,
    &.active {
      background-color: ${theme('colors.lightestGray')};
      border-bottom: 0.125rem solid ${theme('colors.greenAqua')};
      color: ${theme('link.color')};
      text-decoration: none;
    }
  }
`;

export default StyledLink;
