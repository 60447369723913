import { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { TrainingAdminContext, buildFilterObj, PER_PAGE } from 'routes/EngageAdmin/Dashboard/context';

export const GET_PAGED_INF_LEARNING_TEMPLATES = gql`
  query getPagedInfLearningTemplates($page: Int!, $perPage: Int!, $market: ID, $templateCategories: [ID]) {
    pagedInfLearningTemplates(
      page: $page
      perPage: $perPage
      filter: { markets: $market, templateCategories: $templateCategories }
    ) {
      infLearningTemplatesCount
      infLearningTemplates {
        description
        id
        name
      }
    }
  }
`;

const useGetInfLearningTemplates = options => {
  const { page, currentMarket, categoryFilters } = useContext(TrainingAdminContext);

  return useQuery(GET_PAGED_INF_LEARNING_TEMPLATES, {
    fetchPolicy: 'cache-first',
    ...options,
    variables: {
      page,
      perPage: PER_PAGE,
      ...buildFilterObj({ currentMarket, categoryFilters }),
    },
  });
};

export default useGetInfLearningTemplates;
