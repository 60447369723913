import omit from 'lodash/omit';

export * from './dates';
export * from './functions';
export * from './localStorage';
export * from './accessibility';

export function timeout(delay) {
  return new Promise(res => setTimeout(res, delay));
}

export const omitDeep = (obj, removeKey) => {
  let newObj = omit(obj, removeKey);
  for (const key in obj) {
    if (Array.isArray(newObj[key])) {
      newObj[key] = newObj[key].map(item => omitDeep(item, removeKey));
    } else if (newObj[key] && typeof newObj[key] === 'object') {
      newObj[key] = omitDeep(newObj[key], removeKey);
    }
  }
  return newObj;
};

export const arrayBufferToBase64 = async file => {
  try {
    return await file.arrayBuffer().then(data => {
      let binary = '';
      const bytes = new Uint8Array(data);

      for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
      }

      return window.btoa(binary);
    });
  } catch (e) {
    console.error(e);
  }
};
