import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import FieldLabel from 'components/Form/FieldLabel';

export const MainSectionInner = styled.div`
  padding: 1.5rem;
  & + & {
    border-top: 1px solid ${theme('colors.lightGray')};
  }
`;

export const StyledLabel = styled(FieldLabel)`
  margin-top: 1rem;
`;
