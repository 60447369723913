import React, { useContext, useEffect, useState } from 'react';
import { NoResultsFound } from '../../commonStyledElements';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'components/Loader';
import { TrainingAdminContext } from '../../context';
import { ResultsListHeader, ResultsTab } from '../../commonStyledElements';
import BadgeGridItem from './components/BadgeGridItem/BadgeGridItem';
import useGetPagedBadges from 'hooks/queries/useGetPagedBadges/useGetPagedBadges';

function BadgeTemplates() {
  const [page, setPage] = useState(0);
  const {
    markets,
    currentMarket,
    setters: { setCurrentMarket },
  } = useContext(TrainingAdminContext);

  useEffect(() => {
    setPage(0);
  }, [currentMarket]);

  const { data, loading, fetchMore } = useGetPagedBadges();

  const badgeTemplates = data?.pagedInfBadgeTemplates?.infLearningTemplates || [];
  const hasMore = badgeTemplates.length < data?.pagedInfBadgeTemplates?.infLearningTemplatesCount;

  const loadMore = () => {
    fetchMore({
      variables: { page: page + 1 },
    }).then(() => {
      setPage(page + 1);
    });
  };

  return (
    <InfiniteScroll
      loadMore={() => !loading && loadMore()}
      hasMore={hasMore && !loading}
      loader={
        <div key={'loader'}>
          <Loader />
        </div>
      }
    >
      {badgeTemplates && (
        <>
          <ResultsListHeader>
            {markets?.map(market => (
              <ResultsTab
                key={market.id}
                active={currentMarket === market.id}
                onClick={() => setCurrentMarket(market.id)}
                tabIndex='0'
              >
                {market.name}
              </ResultsTab>
            ))}
          </ResultsListHeader>
          {badgeTemplates.map(badgeTemplate => (
            <BadgeGridItem key={badgeTemplate.id} template={badgeTemplate} />
          ))}
        </>
      )}
      {!loading && badgeTemplates.length === 0 && <NoResultsFound>No badges found</NoResultsFound>}
    </InfiniteScroll>
  );
}

BadgeTemplates.propTypes = {};

export default BadgeTemplates;
