import React, { useContext } from 'react';
import get from 'lodash/get';
import { Table } from 'reactstrap';
import styled from 'styled-components/macro';
import { getToken } from 'utils/auth';
import { formatDateCentralTime } from 'utils';
import Paginator from 'components/Paginator';
import { P as BaseP } from 'components/Typography';
import { TrainingAdminContext } from 'routes/EngageAdmin/Dashboard/context';
import { ResultsListItemWrapper } from 'routes/EngageAdmin/Dashboard/commonStyledElements';
import AllCertificatesFilters from './components/AllCertificatesFilters';

const PaginatorContainer = styled.div`
  padding: 0 1rem;
`;

const P = styled(BaseP)`
  padding: 1rem;
  text-align: center;
`;

const PER_PAGE = 15;

const certificateUrl = id => {
  return `${process.env.REACT_APP_API_URL}/api/certificate/${id}?bearerToken=${getToken()}`;
};

function AllCertificatesTable() {
  const {
    page,
    certificates,
    totalCertificates,
    setters: { setPage },
  } = useContext(TrainingAdminContext);

  return (
    <>
      <AllCertificatesFilters />
      {certificates && certificates.length !== 0 ? (
        <ResultsListItemWrapper>
          <Table bordered responsive>
            <thead>
              <tr>
                <th>Email</th>
                <th>Name</th>
                <th>Certificate Type</th>
                <th>Certificate Date</th>
                <th>Certificate</th>
              </tr>
            </thead>
            <tbody>
              {certificates.map(item => (
                <tr key={item.id}>
                  <td>{item.userEmail}</td>
                  <td>{get(item, 'referenceObject.title', '')}</td>
                  <td>{item.typeDisplay}</td>
                  <td>{formatDateCentralTime(item.earnedAt)}</td>
                  <td>
                    <a
                      href={certificateUrl(item.id)}
                      aria-label={`Download Certificate for ${get(item, 'referenceObject.title', '')}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Download
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PaginatorContainer>
            <Paginator itemCount={totalCertificates} page={page} perPage={PER_PAGE} setPage={setPage} />
          </PaginatorContainer>
        </ResultsListItemWrapper>
      ) : (
        <P>There are no certificates available to view at this time</P>
      )}
    </>
  );
}

export default React.memo(AllCertificatesTable);
