import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import FieldLabel from 'components/Form/FieldLabel';
import { ErrorMessage, StyledInput, InputWrapper } from './index';

function TextInput({ name, type, label, placeholder, hideErrors, fieldClasses, disabled, heightAuto }) {
  return (
    <Field name={name} type={type}>
      {({ input, meta }) => (
        <InputWrapper className={fieldClasses} heightAuto={heightAuto}>
          {label && <FieldLabel>{label}</FieldLabel>}
          <StyledInput
            {...input}
            invalid={meta.error && meta.touched}
            placeholder={placeholder}
            disabled={disabled}
            aria-label={label || name}
          />
          {!hideErrors && meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
        </InputWrapper>
      )}
    </Field>
  );
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'email', 'password', 'number', 'tel']),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  hideErrors: PropTypes.bool,
  fieldClasses: PropTypes.string,
  disabled: PropTypes.bool,
  heightAuto: PropTypes.bool,
};

TextInput.defaultProps = {
  type: 'text',
  hideErrors: false,
  fieldClasses: '',
  heightAuto: false,
};

export default TextInput;
