import React from 'react';
import styled from 'styled-components/macro';
import { NavbarBrand as BaseNavBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import logoImg from 'assets/images/infinitec-logo.jpeg';

const NavbarBrand = styled(BaseNavBrand)`
  height: 100%;
  padding: 1rem 0;

  & > img {
    max-width: 8.25rem;
    height: 100%;
  }
`;

function NavLogo() {
  return (
    <NavbarBrand tag={Link} to='/'>
      <img src={logoImg} alt={'Infinitec Logo'} />
    </NavbarBrand>
  );
}

export default NavLogo;
