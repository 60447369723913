import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import InputMask from 'react-input-mask';
import FieldLabel from 'components/Form/FieldLabel';
import { ErrorMessage, InputWrapper, StyledInput } from '../index';

const propTypes = {
  mask: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  hideErrors: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

function MaskedField({ mask, name, label = '', placeholder = '', hideErrors = false, type }) {
  return (
    <Field name={name} type={type}>
      {({ input, meta }) => (
        <InputWrapper>
          {label && <FieldLabel>{label}</FieldLabel>}
          <InputMask mask={mask} {...input}>
            {inputProps => <StyledInput {...inputProps} placeholder={placeholder} />}
          </InputMask>
          {!hideErrors && meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
        </InputWrapper>
      )}
    </Field>
  );
}

MaskedField.propTypes = propTypes;

export default MaskedField;
