import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { Row, Col } from 'reactstrap';
import { theme, switchProp } from 'styled-tools';
import dompurify from 'dompurify';
import Truncate from 'components/Truncate';
import { Link } from 'react-router-dom';
import { H5 } from 'components/Typography';
import { Button, ResultsListItemWrapper, CenteredCol, Description } from '../../../commonStyledElements';
import GenerateCertificateButton from 'components/GenerateCertificateButton';
import { CERTIFICATE_TYPES } from 'config/consts';
import { formatDateCentralTime } from 'utils/dates';
import { formatPercent } from 'utils/functions';
import { down } from 'styled-breakpoints';

const AnalyticsWrapper = styled.div`
  text-align: center;
`;

const SectionLabel = styled.label`
  display: block;
  color: ${theme('colors.darkerGray')};
`;

const DatesCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  div {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  ${down('lgMobile')} {
    flex-direction: row;

    div {
      margin: 0.5rem 1rem;
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }
`;

const Analytic = styled.span`
  font-size: 1.5rem;

  ${down('lgMobile')} {
    text-align: center;
  }

  ${switchProp('type', {
    green: css`
      color: ${theme('colors.primary')};
    `,
    yellow: css`
      color: ${theme('colors.gold')};
    `,
    orange: css`
      color: ${theme('colors.red')};
    `,
  })};
`;

const propTypes = {
  learningCampaign: PropTypes.shape({
    description: PropTypes.string,
    campaignAnalytics: PropTypes.shape({
      totalUsers: PropTypes.number,
      notStarted: PropTypes.number,
      inactive: PropTypes.number,
      passed: PropTypes.number,
      percentComplete: PropTypes.number,
    }),
    endDate: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    startDate: PropTypes.string,
    uploadedUsers: PropTypes.arrayOf(
      PropTypes.shape({
        progress: PropTypes.arrayOf(
          PropTypes.shape({
            completedAt: PropTypes.string,
            eventType: PropTypes.string,
            module: PropTypes.shape({
              id: PropTypes.string,
            }),
          })
        ),
        status: PropTypes.string,
      })
    ),
  }),
};

function ResultItem({ learningCampaign }) {
  const {
    id: campaignId,
    name,
    description,
    startDate,
    endDate,
    campaignAnalytics: { totalUsers, inProgress, notStarted, passed, percentComplete },
  } = learningCampaign;

  return (
    <ResultsListItemWrapper>
      <Row>
        <Col md={7}>
          <H5>{name}</H5>
          <Description>
            <Truncate lines={2} ellipsis={<span>...</span>}>
              <span dangerouslySetInnerHTML={{ __html: dompurify.sanitize(description) }} />
            </Truncate>
          </Description>
          <AnalyticsWrapper>
            <Row md={5}>
              <Col>
                <Analytic type='green' aria-label='Total Users'>
                  {totalUsers}
                </Analytic>
                <SectionLabel>Total</SectionLabel>
              </Col>
              <Col>
                <Analytic type='orange' aria-label='Not Started'>
                  {notStarted}
                </Analytic>
                <SectionLabel>Not Started</SectionLabel>
              </Col>
              <Col>
                <Analytic type='yellow' aria-label='In Progress'>
                  {inProgress}
                </Analytic>
                <SectionLabel>In Progress</SectionLabel>
              </Col>
              <Col>
                <Analytic type='green' aria-label='Passed'>
                  {passed}
                </Analytic>
                <SectionLabel>Passed</SectionLabel>
              </Col>
              <Col>
                <Analytic type='green' aria-label='Completion Percent'>
                  {parseInt(formatPercent(percentComplete))}%
                </Analytic>
                <SectionLabel>Completion</SectionLabel>
              </Col>
            </Row>
          </AnalyticsWrapper>
        </Col>
        <DatesCol xs={12} md={3}>
          <div>
            <span aria-label='Start Date'>{formatDateCentralTime(startDate, 'MM/DD/YYYY')}</span>
            <SectionLabel>Start</SectionLabel>
          </div>
          <div>
            <span aria-label='End Date'>{formatDateCentralTime(endDate, 'MM/DD/YYYY')}</span>
            <SectionLabel>Ends</SectionLabel>
          </div>
        </DatesCol>
        <CenteredCol xs={12} md={2}>
          <Button
            as={Link}
            to={`/engage-admin/learning-campaign/edit/${campaignId}`}
            aria-label={`Edit ${learningCampaign.name}`}
          >
            Edit
          </Button>
        </CenteredCol>
        {process.env.NODE_ENV === 'development' && (
          <CenteredCol xs={12}>
            <GenerateCertificateButton id={campaignId} type={CERTIFICATE_TYPES.CAMPAIGN} />
          </CenteredCol>
        )}
      </Row>
    </ResultsListItemWrapper>
  );
}

ResultItem.propTypes = propTypes;

export default ResultItem;
