import React from 'react';
import PropTypes from 'prop-types';
import { default as InnerLoader } from 'react-loader-spinner';
import theme from 'theme';
import styled from 'styled-components/macro';

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 6.25rem;
`;

const Loader = ({ timeout }) => (
  <LoadingWrapper>
    <InnerLoader type='Rings' color={theme.colors.purple} height={180} width={180} timeout={timeout} />
  </LoadingWrapper>
);

Loader.propTypes = {
  timeout: PropTypes.number,
};

Loader.defaultProps = {
  timeout: null,
};

export default Loader;
