import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Input } from 'reactstrap';
import { Field } from 'react-final-form';
import { theme } from 'styled-tools';
import styled from 'styled-components/macro';
import { ErrorMessage } from '../index';
import FieldLabel from '../FieldLabel';
import Options from './Options';

export const Arrow = styled.span`
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 0.375rem solid transparent;
  border-right: 0.375rem solid transparent;
  border-top: 0.5rem solid black;
`;

export const StyledSelectInput = styled(Input).attrs({
  type: 'select',
})`
  &&& {
    appearance: none;
    color: ${theme('colors.inputColor')};
  }

  &:focus {
    border: 0.0625rem solid ${theme('colors.primary')};
  }

  &.form-control {
    border: 0.0625rem solid ${theme('colors.mediumGray')};
  }

  &.input-invalid {
    border: 0.0625rem solid ${theme('colors.red')};
  }
`;

const InputWrapper = styled.div`
  position: relative;

  ${Arrow} {
    right: 1rem;
    top: 2.25rem;
  }
`;

const DEFAULT_OPTION = {
  value: '',
  label: 'Choose One',
  hidden: true,
  disabled: true,
};

function SelectInput({
  children,
  fieldClasses,
  hideErrors,
  label,
  name,
  spacing,
  validate,
  parse,
  options,
  onChange,
  disabled,
}) {
  return (
    <Field name={name} type={'select'} validate={validate} parse={parse}>
      {({ input, meta }) => (
        <InputWrapper className={fieldClasses} spacing={spacing}>
          {label && <FieldLabel>{label}</FieldLabel>}
          <StyledSelectInput
            disabled={disabled}
            {...input}
            onChange={e => {
              onChange && onChange(e);
              input.onChange(e);
            }}
            className={cx({
              'input-invalid': meta.error && meta.touched,
            })}
            aria-label={label || name}
          >
            {options ? <Options options={[DEFAULT_OPTION, ...options]} /> : children}
          </StyledSelectInput>
          <Arrow />
          {!hideErrors && meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
        </InputWrapper>
      )}
    </Field>
  );
}

SelectInput.propTypes = {
  fieldClasses: PropTypes.string,
  hideErrors: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  spacing: PropTypes.string,
  parse: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      hidden: PropTypes.bool,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

SelectInput.defaultProps = {
  fieldClasses: '',
  hideErrors: false,
  label: '',
  placeholder: '',
  spacing: 'withMargin',
  parse: v => v,
  onChange: null,
  options: [
    {
      disabled: false,
      hidden: false,
    },
  ],
};

export default SelectInput;
