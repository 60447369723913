/** global: localStorage */
import jwtDecode from 'jwt-decode';
import { isQuizAdmin, isSuperAdmin, isUCPAdmin } from './roles';

const USER_AUTH_TOKEN = 'c@xy-infinitec-user-auth-t0ken';

export const getToken = () => localStorage.getItem(USER_AUTH_TOKEN);

export const setToken = token => localStorage.setItem(USER_AUTH_TOKEN, token);

export const removeToken = () => localStorage.removeItem(USER_AUTH_TOKEN);

export const getUserData = () => isAuthenticated() && jwtDecode(getToken());

export const isAuthenticated = () => !!getToken();

export const canViewUnpublishedModules = user => isUCPAdmin(user) || isSuperAdmin(user) || isQuizAdmin(user);

export const clearSessionStorage = () => {
  removeToken();
  localStorage.removeItem('userReceivesEmails');
};
