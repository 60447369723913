import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'routes/EngageAdmin/Dashboard/commonStyledElements';
import { getToken } from 'utils/auth';
import { CERTIFICATE_TYPES } from 'config/consts';

const CERTIFICATE_ENDPOINT = {
  [CERTIFICATE_TYPES.CAMPAIGN]: '/api/generateCampaignCertificate',
  [CERTIFICATE_TYPES.COMPLETION]: '/api/generateCertificateOfCompletion',
  [CERTIFICATE_TYPES.ALL]: '/api/generateAllCertificates',
};

// This button is intended for dev use only.
// It allows the generation of various types of certificates.
const GenerateCertificateButton = ({ id, type }) => {
  if (![CERTIFICATE_TYPES.CAMPAIGN, CERTIFICATE_TYPES.COMPLETION, CERTIFICATE_TYPES.ALL].includes(type)) {
    return null;
  }

  const certificateUrl = `${process.env.REACT_APP_API_URL}${
    CERTIFICATE_ENDPOINT[type]
  }/${id}/?bearerToken=${getToken()}`;

  const handleClick = async e => {
    e.preventDefault();

    window.location = certificateUrl;
  };

  return <Button onClick={handleClick}>Generate Certificate</Button>;
};

GenerateCertificateButton.propTypes = {
  id: PropTypes.string,
};

export default GenerateCertificateButton;
