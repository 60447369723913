import React, { useContext } from 'react';
import { NoResultsFound } from '../../commonStyledElements';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'components/Loader';
import ResultItem from './components/ResultItem';
import { TrainingAdminContext } from '../../context';

const propTypes = {};

function LearningPaths() {
  const {
    learningPaths,
    loading,
    hasMore,
    page,
    setters: { setLoading, setPage },
  } = useContext(TrainingAdminContext);

  const loadMore = () => {
    setLoading(true);
    setPage(page + 1);
  };

  return (
    <InfiniteScroll
      loadMore={() => !loading && loadMore()}
      hasMore={hasMore}
      loader={
        <div key={'loader'}>
          <Loader />
        </div>
      }
    >
      {learningPaths &&
        learningPaths.map((learningPath, key) => (
          <ResultItem key={`learning_path_${key}`} learningPath={learningPath} />
        ))}
      {!loading && learningPaths.length === 0 && <NoResultsFound>No Learning Template found</NoResultsFound>}
    </InfiniteScroll>
  );
}

LearningPaths.propTypes = propTypes;

export default LearningPaths;
