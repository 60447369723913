import React from 'react';
import PropTypes from 'prop-types';
import { StyledUncontrolledTooltip as DesktopTooltip } from 'components/Tooltips/SmallTooltip';
import { formatDate } from 'utils';
import dompurify from 'dompurify';
import { userBadgeShape } from 'config/shapes';
import styled from 'styled-components/macro';
import { ErrorBoundary } from 'react-error-boundary';

const BadgeImage = styled.img`
  max-height: 12rem;
`;

const UserBadgeImage = ({ userBadge }) => {
  const id = userBadge.id ? userBadge.id : userBadge.badgeId;

  return (
    <>
      <BadgeImage
        id={`user-badge-${id}`}
        src={userBadge.badgeMeta.image.signedUrl}
        alt={`Digital badge for ${userBadge.badgeMeta.name}. This badge is earned by completing its campaign requirements`}
      />
      <ErrorBoundary fallback={<span />}>
        <DesktopTooltip placement='left' target={`user-badge-${id}`}>
          <p>Badge Name: {userBadge.badgeMeta.name}</p>
          <p>
            Description:
            <div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(userBadge.badgeMeta.description) }} />
          </p>
          <p>Date Awarded: {formatDate(userBadge.completedAt)}</p>
        </DesktopTooltip>
      </ErrorBoundary>
    </>
  );
};

UserBadgeImage.propTypes = {
  userBadge: PropTypes.shape(userBadgeShape),
};

export default UserBadgeImage;
