import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export const ChevronLeft = styled(FontAwesomeIcon).attrs({
  icon: faChevronLeft,
})``;

export const ChevronRight = styled(FontAwesomeIcon).attrs({
  icon: faChevronRight,
})``;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${theme('colors.darkerGray')};
  font-size: 0.8rem;

  ul {
    li {
      display: inline;
      padding: 0 0.25rem;
      cursor: pointer;

      &.selected {
        color: ${theme('colors.black')};
        border-bottom: 1px solid ${theme('colors.primary')};
      }
    }
  }
`;

function Paginator({ itemCount, page, perPage, setPage }) {
  return (
    <PaginationWrapper>
      {itemCount > 0 ? (
        <>
          <p>
            Showing {page * perPage || 1}-{(page + 1) * perPage < itemCount ? (page + 1) * perPage : itemCount} of{' '}
            {itemCount}
          </p>
          <ReactPaginate
            pageCount={Math.ceil(itemCount / perPage)}
            pageRangeDisplayed={5}
            marginPagesDisplayed={1}
            initialPage={page}
            previousLabel={<ChevronLeft />}
            nextLabel={<ChevronRight />}
            onPageChange={({ selected }) => setPage(selected)}
          />
        </>
      ) : (
        <p>No Results Found</p>
      )}
    </PaginationWrapper>
  );
}

Paginator.propTypes = {
  itemCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default Paginator;
