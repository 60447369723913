import { gql } from '@apollo/client';

export const GET_PAGED_LEARNING_PATHS = gql`
  query getPagedLearningPaths($page: Int!, $perPage: Int!) {
    pagedLearningPaths(page: $page, perPage: $perPage) {
      learningPathsCount
      learningPaths {
        description
        id
        name
      }
    }
  }
`;

export const GET_PAGED_USERS = gql`
  query getPagedUsers($page: Int!, $perPage: Int!, $q: String) {
    pagedUsers(page: $page, perPage: $perPage, filter: { q: $q }) {
      usersCount
      users {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const GET_ALL_PAGED_CERTIFICATES = gql`
  query getAllPagedCertificates(
    $page: Int!
    $perPage: Int!
    $startDateFilter: String
    $endDateFilter: String
    $learningCampaignFilter: [String]
    $moduleFilter: String
    $userFilter: String
  ) {
    allPagedCertificates(
      page: $page
      perPage: $perPage
      startDateFilter: $startDateFilter
      endDateFilter: $endDateFilter
      learningCampaignFilter: $learningCampaignFilter
      moduleFilter: $moduleFilter
      userFilter: $userFilter
    ) {
      count: certificatesCount
      certificates {
        id
        typeDisplay
        createdAt
        earnedAt
        referenceObject {
          objectId
          title
        }
        userEmail
      }
    }
  }
`;

export const GET_LEARNING_CAMPAIGNS = gql`
  query getLearningCampaigns {
    learningCampaigns {
      id
      name
    }
  }
`;
