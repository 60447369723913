import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

function ActionButton({ children, text }) {
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);

  return (
    <ButtonDropdown isOpen={open} toggle={toggle}>
      <DropdownToggle caret>{text}</DropdownToggle>
      <DropdownMenu>{children}</DropdownMenu>
    </ButtonDropdown>
  );
}

ActionButton.propTypes = {
  text: PropTypes.string,
};

export default ActionButton;
