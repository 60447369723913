import React from 'react';
import styled, { css } from 'styled-components/macro';
import { theme, switchProp, ifProp } from 'styled-tools';
import { AdaHighlight } from 'theme';

/** Prevents passing custom props to button component unintentionally  creating console errors. */
const Button = styled.button``;

const StyledButton = styled(({ $fullWidth, fixedWidth, kind, forwardedRef, ...props }) => (
  <Button ref={forwardedRef} {...props} />
))`
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border 0.15s ease-in-out;
  margin-left: auto;
  margin-right: auto;
  padding: 0.5rem 1rem;
  text-align: center;
  width: auto;
  text-transform: capitalize;

  ${ifProp(
    'fixedWidth',
    css`
      width: 15rem;
    `
  )}

  ${ifProp(
    '$fullWidth',
    css`
      width: 100%;
    `
  )}

  &:hover {
    text-decoration: none;
  }

  ${AdaHighlight}

  ${switchProp('kind', {
    inactive: css`
      background: none;
      border: 0;
      color: ${theme('button.colors.inactive')};

      &:hover {
        background: ${theme('colors.darkGray')};
        border: 1px solid ${theme('colors.primary')};
        color: ${theme('colors.white')};
      }
    `,
    primary: css`
      background: ${theme('button.backgroundColor')};
      border: 1px solid ${theme('colors.primary')};
      color: ${theme('colors.white')};

      &:hover {
        background: ${theme('colors.darkGray')};
        border: 1px solid ${theme('colors.primary')};
        color: ${theme('colors.white')};
      }
    `,
    secondary: css`
      background: ${theme('colors.secondary')};
      border: 1px solid ${theme('colors.secondary')};
      color: ${theme('colors.white')};

      &:hover {
        background: ${theme('colors.darkGray')};
        border: 1px solid ${theme('colors.secondary')};
        color: ${theme('colors.white')};
      }
    `,
    tertiary: css`
      background: ${theme('colors.white')};
      border: 1px solid ${theme('colors.primary')};
      color: ${theme('colors.black')};

      &:hover {
        background: ${theme('colors.darkGray')};
        border: 1px solid ${theme('colors.primary')};
        color: ${theme('colors.white')};
      }
    `,
    link: css`
      color: ${theme('link.color')};
      margin: 0;
      padding: 0;
      text-align: left;
      background: none;
      border: none;
      font-size: 1rem;
      width: auto;

      &:hover {
        color: ${theme('link.color')};
        text-decoration: underline;
      }
    `,
    disabled: css`
      background: ${theme('colors.disabledGray')};
      cursor: unset;
      &.disabled {
        background: ${theme('colors.disabledGray')};
      }

      &.selected {
        background: ${theme('button.backgroundColor')};
      }
    `,
  })}

  ${ifProp(
    'disabled',
    css`
      background: ${theme('colors.lightGray')};
      border: ${theme('colors.lightGray')};
      color: ${theme('colors.darkGray')};

      &:hover {
        background: ${theme('colors.lightGray')};
        border: ${theme('colors.lightGray')};
        color: ${theme('colors.darkGray')};
        cursor: unset;
      }

      ${switchProp('kind', {
        link: css`
          background: none;
          color: ${theme('colors.mediumGray')};

          &:hover {
            color: ${theme('colors.mediumGray')};
            text-decoration: none;
          }
        `,
      })}
    `
  )}
`;

StyledButton.defaultProps = {
  kind: 'primary',
};

export default StyledButton;
