import React from 'react';
import PropTypes from 'prop-types';
import { StyledInput } from 'components/Form/index';
import styled from 'styled-components/macro';

const IconInputWrapper = styled.div`
  position: relative;

  & > svg {
    position: absolute;
    top: 0.7rem;
    right: 1rem;
  }
`;

const IconInput = ({ icon: Icon, ...props }) => {
  return (
    <IconInputWrapper>
      <StyledInput {...props} />
      <Icon />
    </IconInputWrapper>
  );
};

IconInput.propTypes = {
  icon: PropTypes.elementType,
};

export default IconInput;
