import { useMutation, useQuery } from '@apollo/client';
import React, { useContext, useState, useMemo } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { GET_INFO_TO_CONFIRM, UPDATE_USER_INFO } from './graphql';
import { AuthContext } from 'contexts/authContext';
import Alert from 'components/Alerts';
import InfoConfirmationForm from './InfoConfirmationForm';

function UserInfoConfirmationModal() {
  const { isAuthenticated, userMustConfirmInfo, setUserMustConfirmInfo } = useContext(AuthContext);
  const [alert, setAlert] = useState();
  const { data, loading } = useQuery(GET_INFO_TO_CONFIRM, {
    skip: !isAuthenticated() || !userMustConfirmInfo,
  });
  const [updateUserInfo] = useMutation(UPDATE_USER_INFO, {
    onCompleted: ({ confirmUserInfo }) => {
      if (confirmUserInfo.success) {
        setUserMustConfirmInfo(false);
      } else {
        setAlert({ type: 'warning', message: confirmUserInfo.message });
      }
    },
    onError: err => setAlert({ type: 'error', message: err.message.replace('GraphQL error: ', '') }),
  });

  const isOpen = useMemo(() => {
    return isAuthenticated() && !loading && userMustConfirmInfo && !data?.myUser?.confirmUserInfo;
  }, [data, isAuthenticated, loading, userMustConfirmInfo]);

  const onSubmit = async values => {
    setAlert(null);

    await updateUserInfo({
      variables: {
        email: values.email,
        state: values.state,
        memberAgency: Array.isArray(values.memberAgency) ? values.memberAgency[0].value : values.memberAgency,
      },
    });
  };

  return (
    <Modal isOpen={isOpen} centered>
      <ModalBody>
        {alert && <Alert type={alert.type} alertText={alert.message} />}
        <p>
          To make sure we have the most current information about you, once each year we ask you to confirm or update
          your state, employer and email address. You will not be able to interact with the site until you have
          completed this step. Thank you.
        </p>
        <InfoConfirmationForm onSubmit={onSubmit} myUser={data?.myUser} states={data?.states} />
      </ModalBody>
    </Modal>
  );
}

export default UserInfoConfirmationModal;
