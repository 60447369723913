import React, { useState, useMemo } from 'react';
import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';
import { switchProp, theme } from 'styled-tools';
import { Alert as BootstrapAlert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationCircle, faBan } from '@fortawesome/free-solid-svg-icons';

export const SUCCESS_TYPE = 'success';
export const WARNING_TYPE = 'warning';
export const DANGER_TYPE = 'danger';
export const ERROR_TYPE = 'error';
export const PRIMARY_TYPE = 'primary';

const StyledAlert = styled(BootstrapAlert)`
  color: ${theme('colors.black')};

  ${switchProp('type', {
    [SUCCESS_TYPE]: css`
      background: ${theme('colors.alertSuccess')};
    `,
    [WARNING_TYPE]: css`
      background: ${theme('colors.alertWarning')};
      border-color: ${theme('colors.alertWarningBorder')};
    `,
    [DANGER_TYPE]: css`
      background: ${theme('colors.alertDanger')};
      border-color: ${theme('colors.alertDangerBorder')};
    `,
    [ERROR_TYPE]: css`
      background: ${theme('colors.alertDanger')};
      border-color: ${theme('colors.alertDangerBorder')};
    `,
  })}
`;

const AlertIcon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;

const getIcon = type => {
  switch (type) {
    case WARNING_TYPE:
      return faExclamationCircle;
    case DANGER_TYPE:
    case ERROR_TYPE:
      return faBan;
    case SUCCESS_TYPE:
    default:
      return faCheck;
  }
};

const Alert = ({ alertText, type, color, clearAlert, noIcon, children, canClose = true }) => {
  const icon = useMemo(() => getIcon(type), [type]);
  const [open, setOpen] = useState(true);
  const onDismiss = () => {
    setOpen(false);
    clearAlert && clearAlert();
  };

  React.useEffect(() => {
    setOpen(true);
  }, [alertText]);

  return (
    <StyledAlert color={color} type={type} isOpen={open} toggle={canClose && onDismiss}>
      {!noIcon && <AlertIcon icon={icon} />}
      <span>{alertText}</span>
      {children}
    </StyledAlert>
  );
};

Alert.propTypes = {
  alertText: PropTypes.string,
  type: PropTypes.oneOf([SUCCESS_TYPE, WARNING_TYPE, DANGER_TYPE, ERROR_TYPE, PRIMARY_TYPE]),
  clearAlert: PropTypes.func,
  noIcon: PropTypes.bool,
  canClose: PropTypes.bool,
};

export default Alert;
