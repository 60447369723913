import { PER_PAGE } from '../../routes/EngageAdmin/Dashboard/context';
import { gql, useQuery } from '@apollo/client';

export const PAGED_LEARNING_CAMPAIGNS = gql`
  query getPagedLearningCampaigns(
    $page: Int!
    $perPage: Int!
    $activeStatus: String!
    $sortField: String
    $sortOrder: String
    $q: String
  ) {
    pagedLearningCampaigns(
      page: $page
      perPage: $perPage
      activeStatus: $activeStatus
      sortField: $sortField
      sortOrder: $sortOrder
      q: $q
    ) {
      learningCampaignCount
      learningCampaigns {
        description
        endDate
        id
        name
        startDate
        uploadedUsers {
          progress {
            completedAt
            eventType
            module {
              id
            }
          }
          status
        }
        campaignAnalytics {
          percentComplete
          totalUsers
          inProgress
          notStarted
          passed
        }
      }
    }
  }
`;

const useLearningCampaignsQuery = (args, options) => {
  const { page, currentTab, sorting, q } = args;

  return useQuery(PAGED_LEARNING_CAMPAIGNS, {
    fetchPolicy: 'cache-first',
    variables: {
      page,
      perPage: PER_PAGE,
      activeStatus: currentTab,
      sortField: sorting.field,
      sortOrder: sorting.order,
      q: q,
    },
    ...options,
  });
};

export default useLearningCampaignsQuery;
