import React from 'react';
import PropTypes from 'prop-types';
import { Field, useForm } from 'react-final-form';
import styled from 'styled-components/macro';
import Button from 'components/Button';
import { ErrorMessage } from '../index';
import cx from 'classnames';

const ButtonInput = styled(Button).attrs({
  as: 'span',
})``;

const InputWrapper = styled.div`
  margin-bottom: 1rem;

  [type='radio'] {
    display: none;
  }

  label,
  ${ButtonInput} {
    width: 100%;
    border-width: 1px;
  }
`;

function RadioButton({ name, label, value, callback, disabled = false }) {
  const form = useForm();

  const handleChange = e => {
    e.preventDefault();
    !disabled && form.mutators.setValue && form.mutators.setValue(name, value);
  };

  const buttonType = input => {
    if (input.checked) {
      return 'primary';
    } else if (disabled) {
      return 'disabled';
    } else {
      return 'tertiary';
    }
  };

  return (
    <Field name={name} type='radio' value={value}>
      {({ input, meta }) => (
        <InputWrapper>
          <label htmlFor={value}>
            <input type='radio' id={value} checked={input.checked} {...input} disabled={disabled} />
            <ButtonInput
              kind={buttonType(input)}
              className={cx({ disabled, selected: input.checked })}
              tabIndex='0'
              onKeyPress={e => handleChange(e)}
            >
              {label}
            </ButtonInput>
            {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
          </label>
        </InputWrapper>
      )}
    </Field>
  );
}

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.bool,
};

export default RadioButton;
