import { useQuery, gql } from '@apollo/client';
import React, { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { TypeaheadInput } from 'components/Form';
import { TrainingAdminContext } from '../../../context';
import { ResultsListItemWrapper } from '../../../commonStyledElements';

const GET_FILTER_OPTIONS = gql`
  query getInfLearningTemplateFilters($id: ID) {
    infLearningTemplateFilters(id: $id) {
      templateCategories {
        id
        name
      }
    }
  }
`;

function ResultItem() {
  const {
    categoryFilters,
    currentMarket,
    setters: { setCategoryFilters },
  } = useContext(TrainingAdminContext);

  const { data } = useQuery(GET_FILTER_OPTIONS, {
    fetchPolicy: 'no-cache',
    variables: {
      id: currentMarket,
    },
  });

  const { templateCategories } = get(data, 'infLearningTemplateFilters', {});

  return (
    <ResultsListItemWrapper>
      <Row>
        <Col>
          <label>Select a category</label>
          <TypeaheadInput
            name='categories'
            placeholder='Select a category'
            selected={categoryFilters}
            options={
              templateCategories &&
              sortBy(
                templateCategories.map(category => ({ label: category.name, name: category.id })),
                ['label', 'name']
              )
            }
            onChange={selections => setCategoryFilters(selections)}
          />
        </Col>
      </Row>
    </ResultsListItemWrapper>
  );
}

export default ResultItem;
