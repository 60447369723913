import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import { Field } from 'react-final-form';
import FieldLabel from 'components/Form/FieldLabel';
import { ErrorMessage } from '../index';
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill';

let Link = Quill.import('formats/link');
let builtInFunc = Link.sanitize;
Link.sanitize = function customSanitizeLinkInput(linkValueInput) {
  let val = linkValueInput;

  const existingProtocol = /^\w+:/.test(val);
  if (!existingProtocol && !/^https?:/.test(val)) {
    val = 'https://' + val;
  }

  return builtInFunc.call(this, val); // retain the built-in logic
};

const InputWrapper = styled.div`
  margin-bottom: 1rem;

  .quill {
    border: 0.0625rem solid ${theme('colors.mediumGray')};
    border-radius: ${theme('borderRadius')};

    .ql-toolbar {
      border: none;
      border-bottom: 1px solid #cccccc; // re-creates the border between the toolbar and content
    }

    .ql-container {
      border: none;

      &.ql-disabled {
        background-color: ${theme('colors.disabledGray')};
      }
    }
  }
`;

function RichTextareaInput({ name, label, placeholder, disabled = false }) {
  return (
    <Field name={name} type={'textarea'}>
      {({ input, meta }) => (
        <InputWrapper>
          {label && <FieldLabel>{label}</FieldLabel>}
          <ReactQuill
            {...input}
            invalid={meta.error && meta.touched}
            placeholder={placeholder}
            readOnly={disabled}
            aria-label={label || name}
          />
          {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
        </InputWrapper>
      )}
    </Field>
  );
}

RichTextareaInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default RichTextareaInput;
