import { getUserData } from '../utils/auth';
import { useInitDimensions, useSendPageView } from 'utils/googleAnalytics';

function useGoogleAnalyticsListener() {
  const { userId, memberAgency } = getUserData();

  useInitDimensions(userId, memberAgency);
  useSendPageView(userId);
}

export default useGoogleAnalyticsListener;
