import React from 'react';
import { Helmet } from 'react-helmet';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import UserInfoConfirmationModal from 'components/UserInfoConfirmationModal';
import { ErrorProvider } from 'contexts/ErrorContext';
import GlobalStyle from './GlobalStyle';
import useRouter from './routes/Routes';
import theme from './theme';

function App() {
  const router = useRouter();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle theme={null} />
      <Helmet defaultTitle='Home' titleTemplate='Infinitec - %s' />
      <ErrorProvider>
        <RouterProvider router={router} />
      </ErrorProvider>
      <UserInfoConfirmationModal />
    </ThemeProvider>
  );
}

export default App;
