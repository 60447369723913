import React from 'react';
import { theme } from 'styled-tools';
import styled from 'styled-components/macro';
import { Field } from 'react-final-form';
import { Col, Row } from 'reactstrap';

export const QuestionRow = styled(Row)`
  &:nth-child(2n) {
    background-color: ${theme('colors.lighterGray')};
  }
`;

export const OptionCol = styled(Col)`
  text-align: center;
`;

function MatrixTableRow({ name, label, options = [] }) {
  return (
    <QuestionRow role='radiogroup' aria-labelledby={name}>
      <Col md={6}>
        <label id={name}>{label}</label>
      </Col>
      {options.map(option => (
        <Field name={name} type='radio' value={option.value} key={`${name}-${option.value}`}>
          {({ input, meta }) => (
            <OptionCol>
              <input id={`${name}-${option.value}`} {...input} aria-label={option.label} />
            </OptionCol>
          )}
        </Field>
      ))}
    </QuestionRow>
  );
}

export default MatrixTableRow;
