import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { theme, ifProp } from 'styled-tools';
import { Field } from 'react-final-form';
import BaseSelect from 'react-select';
import { FieldLabel, ErrorMessage } from '../index';
import { Arrow } from '../SelectInput';

const ArrowContainer = styled.span`
  position: relative;

  > span {
    position: absolute;
    right: 1rem;
    top: -0.25rem;
  }
`;

const ArrowContainerWrapper = () => (
  <ArrowContainer>
    <Arrow />
  </ArrowContainer>
);

const Select = styled(BaseSelect)`
  &&&.input-invalid > [class$='-control'] {
    border-color: ${theme('colors.red')};
  }
`;

const CustomOptionWrapper = styled.div`
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  position: relative;

  ${ifProp(
    'isFocused',
    css`
      background: ${theme('colors.backgroundGray')};
    `
  )}

  label {
    margin: 0;
  }

  input[type='checkbox'] {
    margin-right: 0.5rem;
  }
`;

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
};

const defaultProps = {
  placeholder: 'Select all that apply',
};

const customStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      borderColor: theme('colors.mediumGray'),
      boxShadow: 'none',
      '&:hover': {
        borderColor: theme('colors.lightTeal'),
        boxShadow: 'none',
      },
    };
  },
};

const CustomOption = ({ innerProps, label, isSelected, isFocused }) => {
  return (
    <CustomOptionWrapper {...innerProps} isFocused={isFocused}>
      <label htmlFor={label}>
        <input type='checkbox' name={label} checked={isSelected} />
        {label}
      </label>
    </CustomOptionWrapper>
  );
};

const MultiSelectInput = ({ label, ...props }) => {
  return (
    <Field name={props.name} type='select'>
      {({ input, meta }) => {
        // The form input needs value and label, but the submission requires ID,
        // so map the value to the id before setting the form value.
        const handleChange = selectedOptions => {
          selectedOptions =
            selectedOptions &&
            selectedOptions.map(opt => ({
              ...opt,
              id: opt.value,
            }));
          input.onChange(selectedOptions);
        };

        return (
          <>
            {label && <FieldLabel>{label}</FieldLabel>}

            <Select
              className={meta.touched && meta.error && 'input-invalid'}
              styles={customStyles}
              hideSelectedOptions={false}
              isMulti
              components={{
                Option: CustomOption,
                DownChevron: null,
                IndicatorsContainer: ArrowContainerWrapper,
              }}
              {...input}
              {...props}
              onChange={handleChange}
              aria-label={props.name}
            />

            {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
          </>
        );
      }}
    </Field>
  );
};

MultiSelectInput.propTypes = propTypes;
MultiSelectInput.defaultProps = defaultProps;

export default MultiSelectInput;
