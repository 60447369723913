import styled, { css } from 'styled-components/macro';
import { theme } from 'styled-tools';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/Button';
import { StaticSelectInput } from 'components/Form';
import { H3, P } from 'components/Typography';
import { ifProp } from 'styled-tools';
import { CheckIcon } from 'components/Icons';
import BaseButton from 'components/Button';

export const ItemContainer = styled.div`
  background: ${theme('colors.white')};
  box-shadow: ${theme('boxShadow')};
  border-radius: ${theme('borderRadius')};
  padding: 1rem;
  margin-top: 1rem;
`;

export const ListContainer = styled.div`
  margin-bottom: 2rem;
`;

export const CampaignWrapper = styled(ItemContainer)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const DashboardItemContentLeft = styled.div`
  flex: 1;
  padding-right: 1em;

  a {
    text-decoration: underline;
  }
`;

export const DashboardItemContentRight = styled.div`
  flex: 0 1 20%;
  text-align: center;
`;

export const SectionLabel = styled.label`
  display: block;
  color: ${theme('colors.darkerGray')};
  margin-bottom: 0;
`;

export const CampaignTitle = styled.h5`
  color: ${theme('colors.primary')};
`;

export const ToggleIcon = styled(FontAwesomeIcon)`
  margin-left: 0.5em;
`;

export const Module = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const ModulesHeader = styled(Button)`
  color: ${theme('colors.primary')};
  font-weight: bold;
  font-size: 1.2em;
`;

export const ModuleList = styled.div`
  flex-basis: 100%;
  padding-top: 1em;
  margin-top: 1em;
  border-top: 1px solid ${theme('colors.lighterGray')};

  ${Module} {
    border-top: 1px solid ${theme('colors.lighterGray')};
    padding-top: 1em;
    padding-bottom: 1em;

    &:first-child {
      border-top: 0;
    }
  }
`;

export const ModuleTitle = styled(H3)`
  display: block;
  color: ${theme('colors.primary')};
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

export const ViewIcon = styled(FontAwesomeIcon).attrs({
  icon: faChevronCircleRight,
})`
  font-size: 1.875rem;
  display: block;
  margin: 0 auto 0.5rem;
`;

export const CampaignHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledSelect = styled(StaticSelectInput)`
  flex-basis: 40%;
  option {
    color: ${theme('colors.darkGray')};
  }
`;

export const StatusWrapper = styled.div`
  margin-bottom: 1rem;
  button {
    display: inline-block;
  }
`;

export const ModuleContent = styled.div`
  width: 100%;

  ${ifProp(
    'indented',
    css`
      margin-left: 1rem;
    `
  )}
`;

export const RequirementsWrapper = styled.div`
  ${P} {
    margin-bottom: 0;
  }
`;

export const CheckCircledIcon = styled(CheckIcon)`
  color: ${theme('colors.primary')};
  font-size: 2rem;
  margin: 0.2rem 0.35rem 0 0;
`;
export const CompletedModuleInfoWrapper = styled.div`
  display: flex;
`;

export const ButtonSection = styled.div`
  display: flex;

  & button {
    width: 50%;
  }

  & a {
    width: 50%;
  }
`;

export const ModalButton = styled(BaseButton)`
  width: auto;
  font-size: 1rem;
  margin-left: 0;
  margin-right: 0;

  &:not(:last-child) {
    margin-right: 1rem;
  }
`;
