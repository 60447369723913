import React, { createContext, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import useBroadcast from '../hooks/useBroadcast';

const CREATE_QUIZ_ATTEMPT_GET_QUESTIONS = gql`
  mutation createQuizAttempt($id: String!) {
    createQuizAttempt(data: { moduleId: $id }) {
      code
      message
      quizAttempt {
        ...QuizAttemptContentFragment
      }
    }
  }
`;

export const QuizContext = createContext({});

export function QuizProvider() {
  const { moduleId } = useParams();
  const navigate = useNavigate();
  const [quizAttempt, setQuizAttempt] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [createQuizAttemptQuery] = useMutation(CREATE_QUIZ_ATTEMPT_GET_QUESTIONS);

  const broadcast = useBroadcast('dashboard');

  function startQuiz() {
    createQuizAttemptQuery({ variables: { id: moduleId } })
      .then(({ data }) => {
        setQuizAttempt(data.createQuizAttempt);
        setQuestions(data.createQuizAttempt.quizAttempt.quizQuestions);

        navigate(`/quiz/${moduleId}/questions`);
      })
      .catch(result => {
        console.error('catch result : ', result);
      });
  }

  return (
    <QuizContext.Provider
      value={{
        currentQuizAttempt: quizAttempt,
        setCurrentQuizAttempt: setQuizAttempt,
        questions,
        setQuestions,
        startQuiz,
        broadcast,
      }}
    >
      <Outlet />
    </QuizContext.Provider>
  );
}
