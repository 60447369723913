import React from 'react';
import styled from 'styled-components/macro';
import { StyledSelectInput, Arrow } from './';
import Options from './Options';
import FieldLabel from '../FieldLabel';

const StaticSelectWrapper = styled.div`
  position: relative;

  ${Arrow} {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
`;

const StaticSelect = ({ disabled, onChange, options, children, className, name, title, label }) => {
  return (
    <>
      {label && <FieldLabel>{label}</FieldLabel>}
      <StaticSelectWrapper className={className}>
        <StyledSelectInput disabled={disabled} onChange={onChange} name={name} title={title}>
          {options ? <Options options={[...options]} /> : children}
        </StyledSelectInput>
        <Arrow />
      </StaticSelectWrapper>
    </>
  );
};

export default StaticSelect;
