import { useEffect, useState } from 'react';
import { useBadgeGuard } from 'components/Guards/BadgeGuard';
import { getEngageTab, setEngageTab } from 'utils';
import LearningCampaigns from '../components/LearningCampaigns';
import LearningPaths from '../components/LearningPaths';
import InfBadgeTemplate from '../components/InfBadgeTemplate';
import InfLearningTemplates from '../components/InfLearningTemplates';
import UserReport from '../components/UserReport';

export const ENGAGE_ADMIN_TABS = {
  LEARNING_CAMPAIGNS: 'LearningCampaigns',
  LEARNING_PATHS: 'LearningPaths',
  INF_LEARNING_TEMPLATES: 'InfLearningTemplates',
  INF_BADGE_TEMPLATE: 'InfBadgeTemplate',
  USER_REPORT: 'user_report',
};

const DEFAULT_TAB = ENGAGE_ADMIN_TABS.LEARNING_CAMPAIGNS;

const TAB_COMPONENTS = {
  [ENGAGE_ADMIN_TABS.LEARNING_CAMPAIGNS]: LearningCampaigns,
  [ENGAGE_ADMIN_TABS.LEARNING_PATHS]: LearningPaths,
  [ENGAGE_ADMIN_TABS.INF_BADGE_TEMPLATE]: InfBadgeTemplate,
  [ENGAGE_ADMIN_TABS.INF_LEARNING_TEMPLATES]: InfLearningTemplates,
  [ENGAGE_ADMIN_TABS.USER_REPORT]: UserReport,
};

export function useGetEngageTabs() {
  const hasBadgeAccess = useBadgeGuard();

  return [
    {
      id: ENGAGE_ADMIN_TABS.LEARNING_CAMPAIGNS,
      label: 'Learning Campaign',
    },
    {
      id: ENGAGE_ADMIN_TABS.LEARNING_PATHS,
      label: 'Our Templates',
    },
    {
      id: ENGAGE_ADMIN_TABS.INF_BADGE_TEMPLATE,
      label: 'Infinitec Badges',
      skip: () => hasBadgeAccess,
    },
    {
      id: ENGAGE_ADMIN_TABS.INF_LEARNING_TEMPLATES,
      label: 'Infinitec Templates',
    },
    {
      id: ENGAGE_ADMIN_TABS.USER_REPORT,
      label: 'User Transcript',
    },
  ].filter(tab => !tab.skip || tab.skip());
}

function useEngageDashTabs() {
  const localStorageTab = getEngageTab();
  const engageTabs = useGetEngageTabs();
  const selectedTab = engageTabs.find(({ id }) => id === localStorageTab);
  const [tabId, setTabId] = useState(selectedTab ? selectedTab.id : DEFAULT_TAB);

  useEffect(() => {
    if (tabId !== localStorageTab) {
      setEngageTab(tabId);
    }
  }, [tabId, localStorageTab]);

  return {
    tabs: engageTabs,
    tabId: tabId,
    tab: selectedTab,
    Component: TAB_COMPONENTS[tabId],
    setTab: setTabId,
  };
}

export default useEngageDashTabs;
