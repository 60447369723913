import { gql } from '@apollo/client';

export const GET_INFO_TO_CONFIRM = gql`
  query myUser {
    myUser {
      id
      confirmUserInfo
      email
      state {
        id
        name
      }
      memberAgency {
        id
        name
      }
    }
    states {
      value: id
      label: name
    }
  }
`;

export const UPDATE_USER_INFO = gql`
  mutation confirmUserInfo($email: Email!, $state: ID!, $memberAgency: ID!) {
    confirmUserInfo(data: { email: $email, state: $state, memberAgency: $memberAgency }) {
      code
      message
      success
    }
  }
`;
