import { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { TrainingAdminContext, buildFilterObj, PER_PAGE } from 'routes/EngageAdmin/Dashboard/context';

export const GET_PAGED_INF_BADGES = gql`
  query getPagedInfBadgeTemplates($page: Int!, $perPage: Int!, $market: ID, $templateCategories: [ID]) {
    pagedInfBadgeTemplates(
      page: $page
      perPage: $perPage
      filter: { markets: $market, templateCategories: $templateCategories }
    ) {
      infLearningTemplatesCount
      infLearningTemplates {
        id
        description
        name
        totalRunTime
        markets {
          name
        }
        enforceActivitySequence
        modules {
          includeQuiz
          module {
            title
            id
          }
        }
        badge {
          id
          name
          description
          learningOutcome
          level
          introModule {
            id
            title
          }
          outroModule {
            id
            title
          }
          image {
            signedUrl
          }
        }
      }
    }
  }
`;

const useGetPagedBadges = options => {
  const { page, currentMarket, categoryFilters } = useContext(TrainingAdminContext);

  return useQuery(GET_PAGED_INF_BADGES, {
    fetchPolicy: 'cache-first',
    ...options,
    variables: {
      page,
      perPage: PER_PAGE,
      ...buildFilterObj({ currentMarket, categoryFilters }),
    },
  });
};

export default useGetPagedBadges;
