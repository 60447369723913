import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { ErrorMessage } from '../index';

const propTypes = {
  name: PropTypes.string.isRequired,
};

const defaultProps = {
  name: '',
};

const RecaptchaInput = ({ name }) => {
  return (
    <Field name={name} type='checkbox'>
      {({ input, meta }) => {
        let { type, ...fieldInputs } = input;

        return (
          <div>
            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY} {...fieldInputs} />
            {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
          </div>
        );
      }}
    </Field>
  );
};

RecaptchaInput.propTypes = propTypes;
RecaptchaInput.defaultProps = defaultProps;

export default RecaptchaInput;
