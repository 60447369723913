import React, { useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { IconInput, InputWrapper } from 'components/Form';
import { SearchIcon } from 'components/Icons';
import { StyledUncontrolledTooltip as BaseTooltip } from 'components/Tooltips/SmallTooltip';

function CampaignFilter({ disabled, setFilter, placeholder }) {
  const debounceSetFilter = useCallback(
    debounce(value => {
      setFilter(value);
    }, 500),
    [setFilter] // Dependency for stability
  );
  useEffect(() => {
    // Cleanup on unmount or when dependencies change
    return () => {
      debounceSetFilter.cancel();
    };
  }, [debounceSetFilter]);
  const onSearchChange = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }

    return debounceSetFilter(e.target && e.target.value);
  };

  return (
    <>
      <BaseTooltip placement='bottom' target='search'>
        {placeholder}
      </BaseTooltip>
      <InputWrapper>
        <label htmlFor='search'>Campaign Name or Member Email</label>
        <IconInput
          id='search'
          name='q'
          type='text'
          onChange={onSearchChange}
          onKeyPress={onSearchChange}
          icon={SearchIcon}
          placeholder={placeholder || 'Search...'}
          autocomplete='off'
          disabled={disabled}
        />
      </InputWrapper>
    </>
  );
}

CampaignFilter.propTypes = {};

export default CampaignFilter;
