import styled, { css } from 'styled-components/macro';
import { ifProp, theme } from 'styled-tools';
import BaseButton from 'components/Button';
import { Col } from 'reactstrap';
import { P } from 'components/Typography';
import { up } from 'styled-breakpoints';

// TODO : Refactor : This component duplicated from Online ClassRoom.
export const SideBarItem = styled.div`
  background: ${theme('colors.white')};
  box-shadow: ${theme('boxShadow')};
  border-radius: ${theme('borderRadius')};
  margin-bottom: 1.25rem;
  padding: 1rem;
`;

// TODO : Refactor : This component duplicated from Online ClassRoom.
export const ResultList = styled.div`
  background: ${theme('colors.white')};
  box-shadow: ${theme('boxShadow')};
  border-radius: ${theme('borderRadius')};
  margin-bottom: 1.25rem;
`;

export const Button = styled(BaseButton)`
  width: auto;
  font-size: 1rem;
  margin-left: 0;
  margin-right: 0;

  &:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const NoResultsFound = styled.div`
  text-align: center;
  padding: 1rem;
`;

export const ResultsListItemWrapper = styled.div`
  border-bottom: 1px solid ${theme('colors.lightGray')};
  padding: 1.25rem;
`;

export const CenteredCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Description = styled(P)`
  margin-bottom: 0.5rem;
`;

// TODO : Refactor : This component duplicated from Online ClassRoom.
export const ResultsListHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme('colors.lightGray')};
`;

// TODO : Refactor : This component duplicated from Online ClassRoom.
export const tabHoverActiveStyles = css`
  color: ${theme('link.color')};
  background: ${theme('colors.lightestGray')};
  border-bottom: 2px solid ${theme('colors.primary')};
`;

// TODO : Refactor : This component duplicated from Online ClassRoom.
export const ResultsTab = styled.button`
  padding: 1.25rem 1.5rem;
  font-size: 0.8125rem;
  background-color: ${theme('colors.white')};
  outline: 0;
  border: 0;
  border-bottom: 2px solid transparent;
  cursor: pointer;

  ${ifProp('active', tabHoverActiveStyles)}

  &:hover {
    ${tabHoverActiveStyles}
  }

  ${up('lgTablet')} {
    padding: 1.25rem 3rem;
  }
`;
