import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Form } from 'react-final-form';
import Button, { ButtonWrapper } from 'components/Button';
import SelectEmployer from 'components/Form/SelectEmployer';
import SelectInput from 'components/Form/SelectInput';
import { setValue } from 'utils/formMutators';
import { isValidEmail } from 'utils/validators';
import { TextInput } from '../Form';

const InputWrapper = styled.div`
  margin-bottom: 1rem;
`;

function validate(values) {
  const errors = {};

  if (!isValidEmail(values.email)) {
    errors.email = 'Please enter a valid email';
  }

  if (values.email !== values.emailConfirm) {
    errors.emailConfirm = 'Entry must match Email';
  }

  if (values.emailConfirm && !isValidEmail(values.emailConfirm)) {
    errors.emailConfirm = 'Please enter a valid email';
  }
  if (!values.state) {
    errors.state = 'Please select a state';
  }

  if (!values.memberAgency || values.memberAgency.length === 0) {
    errors.memberAgency = 'Please select an employer';
  }

  return errors;
}

function InfoConfirmationForm({ onSubmit, myUser, states = [] }) {
  const [selectedState, setSelectedState] = useState();

  const handleStateChange = (value, formMutators) => {
    setSelectedState(value);
    formMutators.setValue('memberAgency', null);
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={
        myUser
          ? {
              email: myUser.email,
              emailConfirm: myUser.email,
              state: myUser.state.id,
              memberAgency: myUser.memberAgency.id,
            }
          : null
      }
      mutators={{ setValue }}
      render={({ handleSubmit, submitting, form: { mutators } }) => {
        return (
          <form onSubmit={handleSubmit}>
            <InputWrapper>
              <SelectInput
                onChange={e => handleStateChange(e.target.value, mutators)}
                label='Employer State'
                name='state'
                options={states}
              />
            </InputWrapper>

            <InputWrapper>
              <SelectEmployer
                selectedState={selectedState ?? myUser?.state.id}
                initMemberAgency={myUser?.memberAgency.id}
              />
            </InputWrapper>

            <TextInput label='Email' name='email' type='email' placeholder='Email' heightAuto />

            <TextInput label='Confirm Email' name='emailConfirm' type='email' placeholder='Confirm Email' heightAuto />

            <ButtonWrapper flush>
              <Button type='submit' disabled={submitting} $fullWidth>
                Submit
              </Button>
            </ButtonWrapper>
          </form>
        );
      }}
    />
  );
}

InfoConfirmationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  myUser: PropTypes.shape({
    state: PropTypes.object.isRequired,
    memberAgency: PropTypes.object.isRequired,
  }),
  states: PropTypes.array,
};

export default InfoConfirmationForm;
