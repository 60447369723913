import { useQuery } from '@apollo/client';
import React, { useCallback, useContext, useState } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { debounce } from 'lodash';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { GET_LEARNING_CAMPAIGNS } from 'routes/EngageAdmin/Dashboard/context/graphql';
import { MainSectionInner } from 'routes/EngageAdmin/LearningCampaign/components/common';
import { SearchIcon } from 'components/Icons';
import { FieldLabel, IconInput, InputWrapper, TypeaheadInput } from 'components/Form';
import { DateIcon, DatePickerWrapper, StyledDatePicker } from 'components/Form/DatePickerInput';
import usePagedQuery from '../../components/useUsers';
import { AllBadgesContext } from '../allBadges.context';
import useGetInfBadgeTemplates from 'hooks/queries/useGetInfBadgeTemplates/useGetInfBadgeTemplates';

function AllBadgesFilters() {
  const [allLearningCampaigns, setAllLearningCampaigns] = useState([]);
  const [allBadges, setAllBadges] = useState([]);
  const { error, setError, alert } = usePagedQuery();
  const [showAlert, setShowAlert] = useState(false);

  const { setters, learningCampaignFilter, startDateFilter, endDateFilter, badgeNameFilter } = useContext(
    AllBadgesContext
  );

  const {
    setUserFilter,
    setStartDateFilter,
    setEndDateFilter,
    setLearningCampaignFilter,
    setBadgeNameFilter,
  } = setters;

  // Get all learning campaigns that are part of the current user's memberAgency
  useQuery(GET_LEARNING_CAMPAIGNS, {
    onCompleted: data => {
      const allLearningCampaigns = get(data, 'learningCampaigns', []);
      setAllLearningCampaigns(allLearningCampaigns);
    },
    onError: () => {
      setError('There was an error when loading Learning Campaigns. Please try again.');
    },
  });

  useGetInfBadgeTemplates({
    onCompleted: data => {
      const badges = get(data, 'infBadgeTemplates', []).map(template => template.badge);
      setAllBadges(badges);
    },
  });

  const debounceSetUserFilter = useCallback(debounce(setUserFilter, 500), []);

  // Event handlers
  const handleLearningCampaignFilterChange = learningCampaignValue => {
    return setLearningCampaignFilter(learningCampaignValue);
  };

  const handleBadgeFilterChange = badgeFilterValue => {
    return setBadgeNameFilter(badgeFilterValue);
  };

  const onUserFilterChange = e => {
    return debounceSetUserFilter(e.target && e.target.value);
  };
  const onStartDateFilterChange = e => {
    return setStartDateFilter(e);
  };
  const onEndDateFilterChange = e => {
    return setEndDateFilter(e);
  };

  return (
    <MainSectionInner>
      {error && (
        <Alert
          color='danger'
          toggle={() => {
            setShowAlert(false);
          }}
          isOpen={showAlert}
        >
          {error}
        </Alert>
      )}
      {alert && (
        <Alert
          toggle={() => {
            setShowAlert(false);
          }}
          isOpen={showAlert}
        >
          {alert}
        </Alert>
      )}
      <Row>
        <Col>
          <FieldLabel htmlFor='learning-campaign-filter'>Select a learning campaign</FieldLabel>
          <TypeaheadInput
            name='learningCampaigns'
            placeholder='Select a learning campaign'
            selected={learningCampaignFilter}
            options={
              allLearningCampaigns &&
              sortBy(
                allLearningCampaigns.map(category => ({ label: category.name, name: category.id })),
                ['label', 'name']
              )
            }
            onChange={selections => handleLearningCampaignFilterChange(selections)}
          />
        </Col>

        <Col sm={6} lg={6}>
          <InputWrapper>
            <FieldLabel htmlFor='email-search'>Search staff members</FieldLabel>
            <IconInput
              id='user-search'
              name='q'
              type='email'
              onChange={onUserFilterChange}
              onKeyPress={onUserFilterChange}
              icon={SearchIcon}
            />
          </InputWrapper>
        </Col>
      </Row>
      <Row>
        <Col sm={6} lg={6}>
          <FieldLabel htmlFor='start-date-search'>Start Date</FieldLabel>
          <DatePickerWrapper>
            <DateIcon title='calendar' />
            <StyledDatePicker showClearButton={false} onChange={onStartDateFilterChange} value={startDateFilter} />
          </DatePickerWrapper>
        </Col>
        <Col sm={6} lg={6}>
          <FieldLabel htmlFor='start-date-search'>End Date</FieldLabel>
          <DatePickerWrapper>
            <DateIcon title='calendar' />
            <StyledDatePicker showClearButton={false} onChange={onEndDateFilterChange} value={endDateFilter} />
          </DatePickerWrapper>
        </Col>
      </Row>
      <Row>
        <Col sm={6} lg={6}>
          <FieldLabel htmlFor='badge-name-search'>Badge</FieldLabel>
          <TypeaheadInput
            name='badge-name-search'
            placeholder='Select a badge'
            selected={badgeNameFilter}
            options={
              allBadges &&
              sortBy(
                allBadges.map(badge => ({ label: badge.name, name: badge.name })),
                ['label', 'name']
              )
            }
            onChange={selections => handleBadgeFilterChange(selections)}
          />
        </Col>
      </Row>
    </MainSectionInner>
  );
}

export default AllBadgesFilters;
