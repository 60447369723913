import { useState } from 'react';

function usePagedQuery() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alert, setAlert] = useState(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  return {
    loading,
    setLoading,
    error,
    setError,
    alert,
    setAlert,
    page,
    setPage,
    hasMore,
    setHasMore,
  };
}

export default usePagedQuery;
