import styled, { css } from 'styled-components/macro';
import { theme, ifProp } from 'styled-tools';

// TODO the following styles are template duplication left overs.  There will be a ticket in the future that will set these and any other values per the project moodboard.

const H1 = styled.h1`
  font: 700 2.625rem ${theme('fonts.title')};
  margin-bottom: 2rem;
`;

const H2 = styled.h2`
  font: 700 2rem ${theme('fonts.heading')};
`;

const H3 = styled.h3`
  font: 700 1.625rem ${theme('fonts.heading')};
`;

const H4 = styled.h4`
  font: 700 1.375rem ${theme('fonts.heading')};
`;

const H5 = styled.h5`
  font: 700 1.125rem ${theme('fonts.heading')};
`;

const H6 = styled.h6`
  font: 700 1rem ${theme('fonts.heading')};
`;

const Subheader = styled.div`
  color: ${theme('colors.black')};
  font-size: 1.5rem;
`;

const P = styled.p`
  font-size: 0.875rem;
  line-height: 1.125rem;

  ${ifProp(
    'centered',
    css`
      text-align: center;
    `
  )}

  ${ifProp(
    'bold',
    css`
      font-weight: bold;
    `
  )}

  ${ifProp(
    'italic',
    css`
      font-style: italic;
    `
  )}

  ${ifProp(
    '$noBottomMargin',
    css`
      margin-bottom: 0;
    `
  )}

  ${ifProp(
    'paddingTop',
    css`
      padding-top: 1rem;
    `
  )}
`;

export const OL = styled.ol`
  font-size: 0.875rem;
  line-height: 1.125rem;
`;

export const Copy = styled.p`
  color: ${theme('colors.black')};
  font-size: 0.875rem;
  line-height: 1.3125rem;
  margin-bottom: 1.5rem;
`;

export const SmallTitle = styled.p`
  color: ${theme('colors.darkerGray')};
  font-size: 0.75rem;
  margin-bottom: 0.375rem;
`;

export const SmallLabel = styled.label`
  color: ${theme('colors.darkerGray')};
  font-size: 0.75rem;
  margin-bottom: 0.375rem;
`;

export { H1, H2, H3, H4, H5, H6, Subheader, P };
