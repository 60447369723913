import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import Paginator from 'components/Paginator';
import styled from 'styled-components/macro';
import { P as BaseP } from 'components/Typography';
import { useForm, useFormState } from 'react-final-form';

const PaginatorContainer = styled.div`
  padding: 0 1rem;
`;

const P = styled(BaseP)`
  padding: 1rem;
  text-align: center;
`;

function UserTable({
  users,
  totalUsers,
  currentPage,
  itemsPerPage = 15,
  setPage,
  fieldArrayProps,
  displaySelect = false,
}) {
  const form = useForm();
  const { values } = useFormState();

  if (!users || users.length === 0) {
    return <P>No users found</P>;
  }

  const handleChange = e => {
    const index = values?.users?.findIndex(user => user === e.target.value);

    if (index !== -1) {
      fieldArrayProps.fields.remove(index);
    } else {
      form.mutators.push('users', e.target.value);
    }
  };

  return (
    <>
      <Table bordered responsive>
        <thead>
          <tr>
            {displaySelect && <th></th>}
            <th>Name</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              {displaySelect && (
                <td>
                  <input
                    type='checkbox'
                    onChange={handleChange}
                    value={user.id}
                    checked={values?.users?.find(u => u === user.id)}
                  />
                </td>
              )}
              <td>
                {user.lastName}, {user.firstName}
              </td>
              <td>{user.email}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <PaginatorContainer>
        <Paginator itemCount={totalUsers} page={currentPage} perPage={itemsPerPage} setPage={setPage} />
      </PaginatorContainer>
    </>
  );
}

UserTable.propTypes = {
  users: PropTypes.array.isRequired,
  totalUsers: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  emptyResultsMessage: PropTypes.string,
  fieldArrayProps: PropTypes.object.isRequired,
  displaySelect: PropTypes.bool,
};

export default React.memo(UserTable);
