import { useEffect } from 'react';
import { BroadcastChannel } from 'broadcast-channel';

/**
 * Creates a message stream between browser tabs.
 * https://github.com/pubkey/broadcast-channel
 *
 * @param {string} channel - name of the channel
 * @param {(msg: string) => any} onMessage - event handler
 * @return {BroadcastChannel<any>}
 */
const useBroadcast = (channel, onMessage) => {
  const broadcast = new BroadcastChannel(channel, {
    webWorkerSupport: false, // disabled support for performance
  });

  useEffect(() => {
    return () => {
      return broadcast.close();
    };
  });

  if (onMessage) {
    broadcast.onmessage = onMessage;
  }

  return broadcast;
};

export default useBroadcast;
