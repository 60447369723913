import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import flattenDeep from 'lodash/flattenDeep';

// the number of nav elements before the custom pages + 1
const NAV_START_POINT = 5;

const GET_PAGES_FOR_NAV = gql`
  query customPages {
    customPages {
      id
      parent
      slug
      url
      title
    }
  }
`;

const navTransformer = (page, i) => {
  return { ...page, path: page.url, order: NAV_START_POINT + i };
};

/**
 * Recurses over allPages to generate a tree for the sidebar menu.
 * Returns an array with the various root pages at the top level.
 * Each leaf in each subtree has a reference to its root.
 */
function buildSidebarTree(roots, allPages, menuRoot = null) {
  return roots?.map(root => {
    const children = allPages.filter(page => page.parent === root.id);
    return {
      ...root,
      menuRoot,
      children: buildSidebarTree(children, allPages, !menuRoot ? root.id : menuRoot),
    };
  });
}

export function useGetPagesForNav() {
  const { data, ...otherProps } = useQuery(GET_PAGES_FOR_NAV, {
    fetchPolicy: 'cache-first',
  });

  const [rootCustomPages, sidebarTree, customPages] = useMemo(() => {
    const rootCustomPages = data?.customPages.filter(page => page.parent === null).map(navTransformer);
    const sidebarTree = buildSidebarTree(rootCustomPages, data?.customPages);
    const flattenedTree = flattenDeep(
      sidebarTree?.map(root => [root, ...root.children.map(child => [child, ...child.children])])
    );

    return [rootCustomPages, sidebarTree, flattenedTree];
  }, [data?.customPages]);

  return {
    ...otherProps,
    customPages,
    rootCustomPages,
    sidebars: sidebarTree,
  };
}

export default useGetPagesForNav;
