import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import IconTabs from 'components/Tabs/IconTabs';
import MyAccountMenu from 'components/Nav/components/MyAccountMenu';
import { AuthContext } from 'contexts/authContext';

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const ContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TabsContainer = ({ navTabs, isMobile = false }) => {
  const { isAuthenticated } = React.useContext(AuthContext);
  const ContainerComponent = isMobile ? ContainerMobile : Container;

  return (
    <>
      {isAuthenticated() ? (
        <>
          <ContainerComponent>
            <IconTabs tabs={navTabs} />
          </ContainerComponent>
          <MyAccountMenu isMobile={isMobile} />
        </>
      ) : (
        <NavLink className='nav-link' to='/login'>
          Login
        </NavLink>
      )}
    </>
  );
};

TabsContainer.propTypes = {
  navTabs: PropTypes.array,
  isMobile: PropTypes.bool,
};

export default TabsContainer;
