import React from 'react';
import PropTypes from 'prop-types';
import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components/macro';
import { Field } from 'react-final-form';
import { ErrorMessage } from '../index';

const CheckboxLabel = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;

  ${ifProp(
    { disabled: true },
    css`
      color: grey;
    `
  )}
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 2.875rem;

  ${ifProp(
    { width: 'full' },
    css`
      align-items: center;
      width: 100%;
    `
  )}
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${theme('colors.white')};
  stroke-width: 0.125rem;
  vertical-align: top;
`;

// Hide checkbox visually but remain accessible to screen readers
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 0.0625rem;
  margin: -0.0625rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  vertical-align: top;
  white-space: nowrap;
  width: 0.0625rem;
`;

const StyledCheckbox = styled.div`
  background: ${props => (props.checked ? '#128c87' : '#ffffff')};
  border: 0.0625rem solid ${theme('colors.primary')};
  border-radius: ${theme('borderRadius')};
  display: inline-block;
  height: 1rem;
  transition: all 150ms;
  width: 1rem;

  ${ifProp(
    { disabled: true },
    css`
      background: lightgrey;
    `
  )}

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const PseudoLabel = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  margin-bottom: 0;
  margin-left: 0.375rem;
`;

const propTypes = {
  checked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
};

function CheckboxInput({ checked = false, children, name = '', width = '', disabled = false, ...props }) {
  return (
    <Field name={name} type='checkbox'>
      {({ input, meta }) => (
        <CheckboxWrapper width={width}>
          <CheckboxLabel htmlFor={name} disabled={disabled} {...input}>
            <HiddenCheckbox checked={checked || input.checked} id={name} {...input} disabled={disabled} {...props} />
            <StyledCheckbox checked={checked || input.checked} disabled={disabled}>
              <Icon viewBox='0 0 24 24'>
                <polyline points='20 6 9 17 4 12' />
              </Icon>
            </StyledCheckbox>
            <PseudoLabel>{children}</PseudoLabel>
          </CheckboxLabel>
          {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
        </CheckboxWrapper>
      )}
    </Field>
  );
}

CheckboxInput.propTypes = propTypes;

export default CheckboxInput;
