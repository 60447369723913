import { useCallback, useState } from 'react';

function useInterval() {
  const [interval, setIntervalState] = useState();

  const setInterval = useCallback((value = null) => {
    setIntervalState(value);
  }, []);

  return [interval, setInterval];
}

export default useInterval;
