import { useMutation, gql } from '@apollo/client';
import { useContext } from 'react';
import { AuthContext } from 'contexts/authContext';

export const RENEW_JWT_TOKEN = gql`
  mutation renew {
    renew {
      jwt
    }
  }
`;

function useRenewJWT() {
  const [renewMutation] = useMutation(RENEW_JWT_TOKEN);
  const { handleLogin, clearIntervals, setWarningModalOpen } = useContext(AuthContext);

  const renew = async () => {
    const res = await renewMutation();
    const { jwt } = res.data.renew;

    handleLogin(jwt);
    clearIntervals();
    setWarningModalOpen(false);

    return res;
  };

  return renew;
}

export default useRenewJWT;
