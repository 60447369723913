export const SPACE_KEY_CODE = 32;
export const ENTER_KEY_CODE = 13;

/**
 * Wrapper function to handle key press events for space and enter keys
 * @param {string} event
 * @param {function} onClick
 */
export function accessibleKey(event, onClick) {
  event.preventDefault();
  const code = event.charCode || event.keyCode;

  if (code === SPACE_KEY_CODE || code === ENTER_KEY_CODE) {
    onClick && onClick();
  }
}
