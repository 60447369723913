import React, { useState, useCallback, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Col, Row } from 'reactstrap';
import Loader from 'components/Loader';
import useLearningCampaignsQuery from 'hooks/queries/useLearningCampaignsQuery';
import { MainSectionInner as FilterSection } from 'routes/EngageAdmin/LearningCampaign/components/common';
import { NoResultsFound, ResultsListHeader, ResultsTab } from '../../commonStyledElements';

// sub-components
import CampaignSortFilter from './components/CampaignSortFilter';
import ResultItem from './components/ResultItem';
import CampaignFilter from './components/CampaignFilter';

const campaignTabs = [
  { id: 'ActiveCampaigns', text: 'Active Campaigns' },
  { id: 'InactiveCampaigns', text: 'Inactive Campaigns' },
];

function LearningCampaigns() {
  const [currentTab, setCurrentStatusTab] = useState(campaignTabs[0].id);
  const [page, setPage] = useState(0);
  const [sorting, setSorting] = useState({});
  const [filter, setFilter] = useState('');

  const setFilterState = useCallback(value => {
    setFilter(value);
  }, []);
  const useLearningCampaignsQueryVariables = useMemo(
    () => ({
      page,
      currentTab,
      sorting,
      q: filter,
    }),
    [page, currentTab, sorting, filter]
  );
  const { data, loading, fetchMore } = useLearningCampaignsQuery(useLearningCampaignsQueryVariables);

  const learningCampaigns = data?.pagedLearningCampaigns?.learningCampaigns || [];
  const hasMore = learningCampaigns.length < data?.pagedLearningCampaigns?.learningCampaignCount;

  const handleTabChange = tabId => {
    setPage(0);
    setCurrentStatusTab(tabId);
  };

  const loadMore = useCallback(() => {
    if (loading || !hasMore) return;

    fetchMore({
      variables: { page: page + 1 },
    }).then(() => {
      setPage(page + 1);
    });
  }, [loading, hasMore, fetchMore, page]);

  return (
    <div>
      <ResultsListHeader>
        {campaignTabs.map(statusTab => (
          <ResultsTab
            key={statusTab.id}
            active={currentTab === statusTab.id}
            onClick={() => handleTabChange(statusTab.id)}
            tabIndex='0'
          >
            {statusTab.text}
          </ResultsTab>
        ))}
      </ResultsListHeader>

      <FilterSection>
        <Row>
          <Col>
            <CampaignSortFilter disabled={loading} setSorting={setSorting} />
          </Col>
          <Col>
            <CampaignFilter
              disabled={loading}
              setFilter={setFilterState}
              placeholder='Search by campaign name or member email'
            />
          </Col>
        </Row>
      </FilterSection>

      <hr />

      <InfiniteScroll
        loadMore={() => !loading && loadMore()}
        hasMore={hasMore && !loading}
        loader={<Loader key='loader' />}
      >
        {!loading && learningCampaigns.length ? (
          learningCampaigns.map(learningCampaign => (
            <ResultItem key={learningCampaign.id} learningCampaign={learningCampaign} />
          ))
        ) : (
          <NoResultsFound>No Learning Campaigns found</NoResultsFound>
        )}
      </InfiniteScroll>
    </div>
  );
}

LearningCampaigns.propTypes = {};

export default LearningCampaigns;
