import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  options: PropTypes.array,
};

const defaultProps = {
  options: [],
};

const Options = ({ options }) => {
  const sortedOptions = options
    .filter(option => option.label === 'Choose One')
    .concat(options.filter(option => option.label !== 'Choose One').sort((a, b) => a.label.localeCompare(b.label)));

  return sortedOptions.map(option => (
    <option key={`${option.name}|${option.value}`} value={option.value}>
      {option.label}
    </option>
  ));
};

Options.propTypes = propTypes;
Options.defaultProps = defaultProps;

export default Options;
