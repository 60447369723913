import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import dompurify from 'dompurify';
import Truncate from 'components/Truncate';
import { H5 } from 'components/Typography';
import { Button, ResultsListItemWrapper, CenteredCol, Description } from '../../../commonStyledElements';
import { Link } from 'react-router-dom';

const propTypes = {
  learningPath: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

function ResultItem({ learningPath }) {
  const { id: learningPathId, name, description } = learningPath;

  return (
    <ResultsListItemWrapper>
      <Row>
        <Col xs={10}>
          <H5>{name}</H5>
          <Description>
            <Truncate lines={4} ellipsis={<span>...</span>}>
              <span
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(description),
                }}
              />
            </Truncate>
          </Description>
        </Col>
        <CenteredCol>
          <Button
            as={Link}
            to={`/engage-admin/learning-path/edit/${learningPathId}`}
            aria-label={`Edit ${learningPath.name}`}
          >
            Edit
          </Button>
        </CenteredCol>
      </Row>
    </ResultsListItemWrapper>
  );
}

ResultItem.propTypes = propTypes;

export default ResultItem;
