import React from 'react';
import styled, { css } from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilePdf,
  faPrint,
  faUpload,
  faDownload,
  faHistory,
  faEnvelope,
  faCheckCircle,
  faSearch,
  faAward,
  faTimes,
  faChevronLeft,
  faVoteYea,
  faAngleDoubleUp,
  faAngleDoubleDown,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faEnvelope as faEnvelopeRegular } from '@fortawesome/free-regular-svg-icons';
import { faGoogle, faWindows } from '@fortawesome/free-brands-svg-icons';
import { ifProp } from 'styled-tools';

const Icon = styled(({ end, ...props }) => <FontAwesomeIcon {...props} />)`
  ${ifProp(
    { end: true },
    css`
      margin-left: 0.5em;
    `,
    css`
      margin-right: 0.5rem;
    `
  )}
`;

export const UploadIcon = styled(Icon).attrs({
  icon: faUpload,
  title: 'upload',
})``;

export const ApproveIcon = styled(Icon).attrs({
  icon: faVoteYea,
  title: 'approve',
})``;

export const DownloadIcon = styled(Icon).attrs({
  icon: faDownload,
  title: 'download',
})``;

export const HistoryIcon = styled(Icon).attrs({
  icon: faHistory,
  title: 'history',
})``;

export const MailIcon = styled(Icon).attrs({
  icon: faEnvelope,
  title: 'email',
})``;

export const PDFIcon = styled(FontAwesomeIcon).attrs({
  icon: faFilePdf,
  title: 'pdf',
})``;

export const PrinterIcon = styled(FontAwesomeIcon).attrs({
  icon: faPrint,
  title: 'print',
})``;

export const CheckIcon = styled(FontAwesomeIcon).attrs({
  icon: faCheckCircle,
  title: 'check',
})``;

export const SearchIcon = styled(FontAwesomeIcon).attrs({
  icon: faSearch,
  title: 'search',
})``;

export const AwardIcon = styled(FontAwesomeIcon).attrs({
  icon: faAward,
  title: 'Award',
})``;

export const CloseIcon = styled(Icon).attrs({
  icon: faTimes,
  title: 'close',
})``;

export const BackIcon = styled(Icon).attrs({
  icon: faChevronLeft,
  title: 'back',
})``;

export const MailRegularIcon = styled(Icon).attrs({
  icon: faEnvelopeRegular,
  title: 'mail',
})``;

export const CollapseIcon = styled(Icon).attrs({
  icon: faAngleDoubleUp,
  title: 'Collapse',
})``;

export const ExpandIcon = styled(Icon).attrs({
  icon: faAngleDoubleDown,
  title: 'Expand',
})``;

export const GoogleIcon = styled(Icon).attrs({
  icon: faGoogle,
  title: 'Google',
})``;

export const WindowsIcon = styled(Icon).attrs({
  icon: faWindows,
  title: 'Windows',
})``;

export const InfoIcon = styled(Icon).attrs({
  icon: faInfoCircle,
  title: 'Information',
})``;
