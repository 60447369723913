export const formatPercent = value => {
  return value * 100;
};

export const formatSecondsToMinutes = value => {
  return (value / 60).toFixed(0);
};

export const formatModuleRunningTime = seconds => {
  const minutes = Math.floor((seconds % 3600) / 60);
  const hours = Math.floor(seconds / 3600);

  return (hours ? `${hours} hr(s) ` : '') + (minutes ? `${minutes} min(s)` : '');
};

export const formatRemainingRunTime = seconds => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const convertSecondsToTimeStamp = playingTimeInSeconds => {
  const seconds = Math.floor((playingTimeInSeconds % 3600) % 60)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((playingTimeInSeconds % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const hours = Math.floor(playingTimeInSeconds / 3600);

  return (hours ? `${hours}:` : '') + (minutes ? `${minutes}:` : hours ? `00:` : '') + (seconds ? `${seconds}` : '00');
};
